import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@ReduxService/store";
import style from "../../styles/Calendar.module.css";
import { useGetDaysAndHoursToRender } from "../../hooks/getDaysAndHoursToShow";
import { useTimeCalculations } from "../../hooks/useTimeCalculations";

export const CurrentTimeMarker = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const viewMode = useSelector(
    (state: RootState) => state.calendarView.viewMode
  );
  const { daySelected } = useGetDaysAndHoursToRender();
  const { HOUR_HEIGHT, PRE_PADDING } = useTimeCalculations();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const shouldShowMarker = () => {
    const today = new Date();
    const selectedDate = new Date(daySelected);
    selectedDate.setDate(selectedDate.getDate() + 1);

    if (viewMode === "week") {
      // Para vista semanal, verificar si estamos en la semana actual
      const startOfWeek = new Date(selectedDate);
      startOfWeek.setDate(selectedDate.getDate() - selectedDate.getDay());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      return today >= startOfWeek && today <= endOfWeek;
    } else if (viewMode === "day") {
      // Para vista diaria, verificar si es el día actual
      return today.toDateString() === selectedDate.toDateString();
    }

    return false;
  };

  const calculateTimeMarkerPosition = () => {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    return (hours * 60 + minutes) * (HOUR_HEIGHT / 60) + PRE_PADDING;
  };

  if (!shouldShowMarker()) return null;

  return (
    <div
      className={style.timeMarker}
      style={{ top: `${calculateTimeMarkerPosition()}px` }}
    >
      <div className={style.timeMarkerCircle} />
    </div>
  );
};
