// appointmentModal.slice.ts
import { createSlice } from "@reduxjs/toolkit";

export interface IAppointmentModalState {
  showFormEditReservation: number | null;
}

const DEFAULT_STATE: IAppointmentModalState = {
  showFormEditReservation: null,
};

const appointmentModalSlice = createSlice({
  name: "appointmentModal",
  initialState: DEFAULT_STATE,
  reducers: {
    setShowFormEditReservation: (state, action) => {
      state.showFormEditReservation = action.payload;
    },
  },
});

export const { setShowFormEditReservation } = appointmentModalSlice.actions;
export default appointmentModalSlice.reducer;
