import CalendarView from "@Pages/views/Calendar.view";
import { Route, Routes } from "react-router-dom";
// @ts-ignore
import { NotFoundPage } from "@viuti/recursos";

export default function AppointmentRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CalendarView />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
