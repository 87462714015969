import { createPortal } from "react-dom";
import { ServiceIcon } from "@Models/icons";
// @ts-ignore
import { Icon } from "@viuti/recursos";
import { ServiceTimeline } from "./ServiceTimeline";
import style from "./AppointmentPopover.module.css";
import { useEffect, useRef, useState } from "react";

export function AppointmentPopover({
  isOpen,
  anchorEl,
  onClose,
  detail,
  currentState,
  textColor,
}) {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [placement, setPlacement] = useState("right");
  const popoverRef = useRef(null);

  useEffect(() => {
    const updatePosition = () => {
      if (!isOpen || !anchorEl || !popoverRef.current) return;

      const anchorRect = anchorEl.getBoundingClientRect();
      const popoverRect = popoverRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const gap = 8;

      let top = 0;
      let left = 0;
      let newPlacement = "right";

      // 1. Intentar posicionar a la derecha
      if (anchorRect.right + gap + popoverRect.width <= viewportWidth) {
        left = anchorRect.right + gap;
        top = anchorRect.top;
        newPlacement = "right";
      }
      // 2. Intentar posicionar a la izquierda
      else if (anchorRect.left - gap - popoverRect.width >= 0) {
        left = anchorRect.left - gap - popoverRect.width;
        top = anchorRect.top;
        newPlacement = "left";
      }
      // 3. Intentar posicionar arriba
      else if (anchorRect.top - popoverRect.height - gap >= 0) {
        top = anchorRect.top - popoverRect.height - gap;
        // Centrar horizontalmente sobre la tarjeta
        left = anchorRect.left + (anchorRect.width - popoverRect.width) / 2;
        // Ajustar si se sale por los lados
        if (left < gap) left = gap;
        if (left + popoverRect.width > viewportWidth) {
          left = viewportWidth - popoverRect.width - gap;
        }
        newPlacement = "top";
      }
      // 4. Si no hay espacio en ningún otro lado, posicionar abajo
      else {
        top = anchorRect.bottom + gap;
        left = anchorRect.left + (anchorRect.width - popoverRect.width) / 2;
        if (left < gap) left = gap;
        if (left + popoverRect.width > viewportWidth) {
          left = viewportWidth - popoverRect.width - gap;
        }
        newPlacement = "bottom";
      }

      setPosition({ top, left });
      setPlacement(newPlacement);
    };

    updatePosition();

    window.addEventListener("resize", updatePosition);
    window.addEventListener("scroll", updatePosition);

    return () => {
      window.removeEventListener("resize", updatePosition);
      window.removeEventListener("scroll", updatePosition);
    };
  }, [isOpen, anchorEl]);

  if (!isOpen || !anchorEl) return null;

  return createPortal(
    <div
      ref={popoverRef}
      className={`${style.popover} ${style[`popover_${placement}`]}`}
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
    >
      <p className={style.modal_client}>{detail.clientName}</p>
      <div className={style.modal_programming}>
        <p className={style.modal_duration}>
          {detail.service.serviceDuration} minutos
        </p>
        <span
          className={style.modal_state}
          style={{
            backgroundColor: currentState?.color,
            color: textColor,
          }}
        >
          {currentState?.name || "Estado desconocido"}
        </span>
      </div>

      <ServiceTimeline
        services={detail.appointment.services}
        currentServiceHour={detail.service.hourReserve}
      />

      <div className={style.modal_programming}>
        <Icon path={ServiceIcon} alt="" color="#392b75" size={16} />
        <p className={style.modal_services}>
          {detail.appointment.services.length}{" "}
          {detail.appointment.services.length === 1 ? "servicio" : "servicios"}
        </p>
      </div>
    </div>,
    document.body
  );
}
