import axios from "axios";
import { authorizeTheCallToApi } from "../API/authorization";
import { GET_APPOINTMENT_STATES_API } from "@Models/services/lounge.services";

export interface IAppointmentState {
  id: number;
  name: string;
  color: string;
}

export const apiGetAppointmentStates = async (): Promise<{
  status: number;
  data: IAppointmentState[];
  message: string;
}> => {
  try {
    const AUTHORIZATION = { ...authorizeTheCallToApi() };
    const CONFIG = {
      ...AUTHORIZATION,
    };

    const response = await axios.get(GET_APPOINTMENT_STATES_API, CONFIG);

    return {
      status: 200,
      data: response.data.data,
      message: response.data.message || "Estados obtenidos exitosamente",
    };
  } catch (error) {
    return {
      status: 500,
      data: [],
      message: error?.response?.data?.message || "Error al obtener los estados",
    };
  }
};
