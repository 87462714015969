import { useMemo } from "react";

export const useTimeCalculations = () => {
  const HOUR_HEIGHT = 64;
  const PRE_PADDING = 32; // Medio HOUR_HEIGHT para el espacio antes de las 00:00

  const calculatePosition = (hour: number) => {
    return hour * HOUR_HEIGHT + PRE_PADDING;
  };

  return {
    HOUR_HEIGHT,
    PRE_PADDING,
    calculatePosition,
  };
};
