import { sumMinutesToTime } from "@Utilities/hours/opertaionsHours";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

// Define the state of the slice
export interface IAppointmentsState {
  uuid: string;
  type: "SERVICE";
  name: string;
  _id: number;
  employee: {
    _id: number;
    name: string;
  };
  price: number;
  duration: number;
  date: string;
  hour: string;
  canEditDateAndHour: boolean;
  reserveDetailId?: number;
}

interface Customer {
  _id: number;
  name: string;
  email: string;
  phone: string;
}

export interface NewIAppointmentsState {
  reserveId: number;
  customer: Customer;
  appointments: IAppointmentsState[];
  appointmentComment: string;
  state: number;
}

// Define the initial state
const DEFAULT_STATE: NewIAppointmentsState = {
  reserveId: 0,
  customer: {
    _id: 0,
    name: "",
    email: "",
    phone: "",
  },
  appointments: [],
  appointmentComment: "",
  state: null,
};

// Create the slice
const calendarViewSlice = createSlice({
  name: "appointments",
  initialState: DEFAULT_STATE,
  reducers: {
    resetToInitialState: (state) => {
      state.appointmentComment = "";
      state.appointments = [];
      state.customer = {
        _id: 0,
        name: "",
        email: "",
        phone: "",
      };
    },
    // Goal: Add a new customer to the appointment
    addNewCustomerToTheAppointment: (
      state,
      action: PayloadAction<Customer>
    ) => {
      state.customer = action.payload;
    },

    // Goal: Add a new item to the appointment
    addNewItemToTheAppointment: (
      state,
      action: PayloadAction<IAppointmentsState>
    ) => {
      const appointmentCopy = state.appointments;
      appointmentCopy.push(action.payload);
    },

    // Goal: Add an empty item to the appointment
    addAnEmptyItemToTheAppointment: (
      state,
      action: PayloadAction<"SERVICE">
    ) => {
      const stateAppointment = state.appointments;
      let initialHour = "";
      let initialDate = "";
      if (stateAppointment.length > 0) {
        const lastState = stateAppointment[stateAppointment.length - 1];
        const calculateTheInitialHour = sumMinutesToTime(
          lastState.hour,
          lastState.duration
        );
        initialHour = calculateTheInitialHour;
        initialDate = lastState.date;
      }
      state.appointments.push({
        uuid: uuid(), // Generate a unique id
        type: action.payload,
        name: "",
        _id: 0,
        price: 0,
        employee: {
          _id: 0,
          name: "",
        },
        date: initialDate,
        hour: initialHour,
        duration: 0,
        canEditDateAndHour: !stateAppointment.length,
      });
    },

    addAServiceNameToAppointment: (
      state,
      action: PayloadAction<{
        uuid: string;
        name: string;
        price: number;
        duration: number;
        priceId: number;
      }>
    ) => {
      const uuid = action.payload.uuid;
      const appointment = state.appointments.find(
        (appointment) => appointment.uuid === uuid
      );
      if (appointment) {
        appointment.name = action.payload.name;
        appointment.price = action.payload.price;
        appointment.duration = action.payload.duration;
        appointment._id = action.payload.priceId;
      }
    },

    addAEmployeeToAppointment: (
      state,
      action: PayloadAction<{
        uuid: string;
        employee: { _id: number; name: string };
      }>
    ) => {
      const uuid = action.payload.uuid;
      const appointment = state.appointments.find(
        (appointment) => appointment.uuid === uuid
      );
      if (appointment) {
        appointment.employee = action.payload.employee;
      }
    },

    addAnHourToAppointment: (
      state,
      action: PayloadAction<{ uuid: string; hour: string }>
    ) => {
      const uuid = action.payload.uuid;
      const appointment = state.appointments.find(
        (appointment) => appointment.uuid === uuid
      );
      if (appointment) {
        appointment.hour = action.payload.hour;
      }
    },

    addADateToAppointment: (
      state,
      action: PayloadAction<{ uuid: string; date: string }>
    ) => {
      const uuid = action.payload.uuid;
      const appointment = state.appointments.find(
        (appointment) => appointment.uuid === uuid
      );
      if (appointment) {
        appointment.date = action.payload.date;
      }
    },

    deleteAnAppointment: (state, action: PayloadAction<string>) => {
      state.appointments = state.appointments.filter(
        (appointment) => appointment.uuid !== action.payload
      );
    },

    addACommentToAppointment: (state, action: PayloadAction<string>) => {
      state.appointmentComment = action.payload;
    },

    addReserveId: (state, action: PayloadAction<number>) => {
      state.reserveId = action.payload;
    },
  },
});

export const {
  addNewItemToTheAppointment,
  addNewCustomerToTheAppointment,
  addAnEmptyItemToTheAppointment,
  addAServiceNameToAppointment,
  addAEmployeeToAppointment,
  addAnHourToAppointment,
  addADateToAppointment,
  deleteAnAppointment,
  addACommentToAppointment,
  resetToInitialState,
  addReserveId,
} = calendarViewSlice.actions;

export default calendarViewSlice.reducer as (
  state: NewIAppointmentsState,
  action: PayloadAction<any>
) => NewIAppointmentsState;
