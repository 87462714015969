import { getLoungeAvailableHourAdapter } from "@Adapters/getLoungeService/getLoungeService.adapter";
import {
  changeMaxAndMinHours,
  setHours,
  setHoursFetchStatus,
} from "@ReduxService/slices/calendar/calendarView.slice";
import { getTheLimitHour } from "@Utilities/hours/getLimitBetweenHours";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export function useGetLoungesHours() {
  // Get and dispatch the lounges hours
  const dispatch = useDispatch();

  const getLoungesHours = async () => {
    // 1. Get the lounges hours
    const loungesResponse = await getLoungeAvailableHourAdapter();

    // 2. If the response is successful, dispatch the lounges hours
    if (loungesResponse.isSuccess) {
      const limitHours = getTheLimitHour(loungesResponse.data);

      // 3. Dispatch the lounges hours
      dispatch(
        changeMaxAndMinHours({
          max: limitHours.maxClosingHour,
          min: limitHours.minOpeningHour,
        })
      );

      dispatch(setHours(loungesResponse.data));
      dispatch(
        setHoursFetchStatus({
          status: "succeeded",
          error: null,
        })
      );
    } else {
      dispatch(
        setHoursFetchStatus({
          status: "failed",
          error: loungesResponse.message,
        })
      );
    }
  };

  // Get the lounges hours when the component is mounted
  // ONLY ONE TIME (For performance reasons ⚡️)
  useEffect(() => {
    getLoungesHours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
