import { useEffect, useRef } from "react";
import { useCalendarColumns } from "./hooks/useCalendarColumns";
import { CalendarColumn } from "./components/CalendarColumn/CalendarColumn";
import HourColumn from "./components/HourColumn/HourColumn";
import { CurrentTimeMarker } from "./components/TimeMarkers/CurrentTimeMarker";
import { CalendarColumnsHeader } from "./components/CalendarColumnsHeader/CalendarColumnsHeader";
import { useTimeCalculations } from "./hooks/useTimeCalculations";
import style from "./styles/Calendar.module.css";

export const Calendar = () => {
  const columnsContainerRef = useRef<HTMLDivElement>(null);
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const { columns } = useCalendarColumns();
  const { HOUR_HEIGHT, PRE_PADDING } = useTimeCalculations();

  useEffect(() => {
    const handleScroll = (e: Event) => {
      if (columnsContainerRef.current && headerRef.current) {
        headerRef.current.scrollLeft = calendarContainerRef.current.scrollLeft;
      }
    };

    const container = calendarContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll, { passive: true });
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const scrollToCurrentTime = () => {
      if (!calendarContainerRef.current) return;

      const now = new Date();
      const currentHour = now.getHours();
      const currentMinutes = now.getMinutes();

      // Calcular posición del scroll para centrar la hora actual
      const timePosition =
        (currentHour * 60 + currentMinutes) * (HOUR_HEIGHT / 60) +
        HOUR_HEIGHT +
        PRE_PADDING;
      const containerHeight = calendarContainerRef.current.clientHeight;
      const scrollPosition = timePosition - containerHeight / 2;

      calendarContainerRef.current.scrollTop = scrollPosition;
    };

    scrollToCurrentTime();
  }, [HOUR_HEIGHT, PRE_PADDING]);

  return (
    <div className={style.container}>
      <CalendarColumnsHeader
        columnsContainerRef={columnsContainerRef}
        headerRef={headerRef}
      />
      <div className={style.calendar__container} ref={calendarContainerRef}>
        <HourColumn />
        <div className={style.dayColumnContainer} ref={columnsContainerRef}>
          <CurrentTimeMarker />
          {columns.map((column) => (
            <CalendarColumn
              key={column.key}
              day={column.day}
              employee={column.employee}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
