// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j2vXvWQxqaErJDkLhwEa {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0;
  padding-left: 12px;
  border-left: 1px solid #e5e7eb;
}

.wfAoiIzuOMXULcABBD2p {
  position: relative;
  padding-left: 20px;
  opacity: 0.7;
}

.wfAoiIzuOMXULcABBD2p.IIgIa6RQAknHHTzRxJ2L {
  opacity: 1;
}

.QR1VZZZVmUMA_trhD5_Z {
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #45348e;
}

.wfAoiIzuOMXULcABBD2p.IIgIa6RQAknHHTzRxJ2L .QR1VZZZVmUMA_trhD5_Z {
  background-color: #392b75;
  border: 2px solid #fff;
  box-shadow: 0 0 0 1px #392b75;
}

.LZbQG2foXxA_cpQJazK5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.ycdAwX25uI4lmlDAzgAR {
  font-weight: 500;
  font-size: 0.825rem;
}

.qcwUnARTgswlRPkonw7v {
  font-size: 0.775rem;
}

.KYTalYM6WNiv93cBYAxz {
  font-size: 0.775rem;
  color: #6b7280;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Calendar/components/AppointmentPopover/ServiceTimeline.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,cAAc;EACd,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,2BAA2B;EAC3B,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".timeline {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin: 12px 0;\n  padding-left: 12px;\n  border-left: 1px solid #e5e7eb;\n}\n\n.timelineItem {\n  position: relative;\n  padding-left: 20px;\n  opacity: 0.7;\n}\n\n.timelineItem.active {\n  opacity: 1;\n}\n\n.timelineDot {\n  position: absolute;\n  left: -5px;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  background-color: #45348e;\n}\n\n.timelineItem.active .timelineDot {\n  background-color: #392b75;\n  border: 2px solid #fff;\n  box-shadow: 0 0 0 1px #392b75;\n}\n\n.timelineContent {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 2px;\n}\n\n.timelineTime {\n  font-weight: 500;\n  font-size: 0.825rem;\n}\n\n.timelineService {\n  font-size: 0.775rem;\n}\n\n.timelineEmployee {\n  font-size: 0.775rem;\n  color: #6b7280;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeline": `j2vXvWQxqaErJDkLhwEa`,
	"timelineItem": `wfAoiIzuOMXULcABBD2p`,
	"active": `IIgIa6RQAknHHTzRxJ2L`,
	"timelineDot": `QR1VZZZVmUMA_trhD5_Z`,
	"timelineContent": `LZbQG2foXxA_cpQJazK5`,
	"timelineTime": `ycdAwX25uI4lmlDAzgAR`,
	"timelineService": `qcwUnARTgswlRPkonw7v`,
	"timelineEmployee": `KYTalYM6WNiv93cBYAxz`
};
export default ___CSS_LOADER_EXPORT___;
