import {
  getServices_adapter,
  Service,
} from "@Adapters/getServices/getServicesv2.adapter";
import Calendar from "@Components/ui/Calendar/Calendar";
import { useGetDaysAndHoursToRender } from "@Components/ui/Calendar/hooks/getDaysAndHoursToShow";
import { CalendarHeader } from "@Components/ui/CalendarHeader/CalendarHeader";
import {
  getAppointmentsAndUpdateCache,
  useGetAppointments,
} from "@Hooks/useGetAppointments";
import { useGetLoungesHours } from "@Hooks/useGetLoungesHours";
import { useManagementModal } from "@Hooks/useManagementModals";
import useWidth from "@Hooks/useWidth";
import {
  SHOW_CALENDAR_UUID,
  UPSERT_APPOINTMENT_UUID,
} from "@Models/constants/securityAccess";
import { AddIcon, LoadingPulseIcon } from "@Models/icons";
import {
  changeAppointmentStatus,
  clearCache,
} from "@ReduxService/slices/appointment/appointments.slice";
import { changeShowEventsOnTheDay } from "@ReduxService/slices/calendar/calendarFilter.slice";
import { updateHeaderDayRow } from "@ReduxService/slices/calendar/calendarHeader.slice";
import { RootState } from "@ReduxService/store";
import {
  CriticalErrorPage,
  FormReservation,
  Icon,
  SlideNotification,
  UnauthorizedMessage,
  validateModuleAccess,
  // @ts-ignore
} from "@viuti/recursos";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "../styles/page.module.css";

export default function CalendarView() {
  const dispatch = useDispatch();
  const [showFormNewReservation, setShowFormNewReservation] = useState(false);

  const [toasterResponse, setToasterResponse] = useState<any>({
    message: "",
    status: 0,
  });
  const hoursFetchStatus = useSelector(
    (state: RootState) => state.calendarView.hoursFetchStatus
  );
  const appointmentsFetchStatus = useSelector(
    (state: RootState) => state.appointments.appointmentsFetchStatus
  );
  const { daySelected } = useGetDaysAndHoursToRender();
  const WIDTH_SCREEN = useWidth();
  const MOBILE_WIDTH = 768;
  const [services, setServices] = useState<Service[]>([]);

  useEffect(() => {
    const getServices = async () => {
      if (services.length === 0) {
        const response = await getServices_adapter();
        setServices(response.data);
      }
    };
    getServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { showFormEditReservation, setShowFormEditReservation } =
    useManagementModal();

  useGetLoungesHours();
  useGetAppointments();

  const handleNewAppointments = (appointment) => {
    dispatch(clearCache());
    const dateObject = new Date(appointment.dateAttention);
    dateObject.setDate(dateObject.getDate() + 1); // TODO
    getAppointmentsAndUpdateCache({
      dispatch,
      appointmentsCached: [],
      day: dateObject,
    });
    dispatch(changeShowEventsOnTheDay(appointment.dateAttention));
    dispatch(updateHeaderDayRow([dateObject.getTime()]));
  };

  const handleDeleteAppointment = (date) => {
    setShowFormEditReservation(null);
    dispatch(clearCache());
    const dateObject = new Date(date);
    dateObject.setDate(dateObject.getDate() + 1); // TODO
    getAppointmentsAndUpdateCache({
      dispatch,
      appointmentsCached: [],
      day: dateObject,
    });
    dispatch(changeShowEventsOnTheDay(date));
    dispatch(updateHeaderDayRow([dateObject.getTime()]));
  };

  const handleStatusAppointment = (newStatusId: number) => {
    const dateObject = new Date(daySelected);
    dispatch(clearCache());

    // Actualizar el estado de la cita en el store de Redux
    dispatch(
      changeAppointmentStatus({
        reserveId: showFormEditReservation,
        newStatus: newStatusId,
      })
    );

    // Recargar las citas para ese día
    getAppointmentsAndUpdateCache({
      dispatch,
      appointmentsCached: [],
      day: dateObject,
    });

    // Actualizar la vista del calendario
    dispatch(changeShowEventsOnTheDay(daySelected));
    dispatch(updateHeaderDayRow([dateObject.getTime()]));

    // setShowFormEditReservation(null);
  };

  const openFormNewReservation = () => {
    setShowFormNewReservation(true);
  };

  if (
    hoursFetchStatus.status === "loading" ||
    appointmentsFetchStatus.status === "loading"
  )
    return (
      <figure className={style.loading_container}>
        <img src={LoadingPulseIcon} alt="Cargando..." />
      </figure>
    );

  if (
    hoursFetchStatus.status === "failed" ||
    appointmentsFetchStatus.status === "failed"
  )
    return <CriticalErrorPage />;

  return (
    <div id="viuti-front-mainContent">
      <main className={style.container}>
        <CalendarHeader
          openFormNewReservation={openFormNewReservation}
          validateModuleAccess={validateModuleAccess}
        />

        {/* Render the calendar */}
        {validateModuleAccess(SHOW_CALENDAR_UUID, false) ? (
          <Calendar />
        ) : (
          <div className={style.unauthorized}>
            <UnauthorizedMessage
              restrictedFeatureName={"Listado de reservas"}
              restrictedUUID={SHOW_CALENDAR_UUID}
            />
          </div>
        )}

        {WIDTH_SCREEN < MOBILE_WIDTH && (
          <footer className={style.commands__footer}>
            <div>
              <button
                className={style.command__buttonAdd}
                onClick={() =>
                  validateModuleAccess(UPSERT_APPOINTMENT_UUID) &&
                  openFormNewReservation()
                }
              >
                <Icon path={AddIcon} color="#fff" size={20} />
              </button>
            </div>
          </footer>
        )}
      </main>
      {showFormNewReservation && (
        <FormReservation
          initialDate={daySelected}
          setToasterResponse={setToasterResponse}
          callBacks={{
            onClose: () => setShowFormNewReservation(false),
            onEditOrCreateSuccess: (date) => handleNewAppointments(date),
          }}
          initialServices={services}
        />
      )}
      {showFormEditReservation && (
        <FormReservation
          setToasterResponse={setToasterResponse}
          reserveId={showFormEditReservation}
          callBacks={{
            onClose: () => setShowFormEditReservation(null),
            onEditOrCreateSuccess: (date) => handleNewAppointments(date),
            onStatusSuccess: (newStatusId: number) =>
              handleStatusAppointment(newStatusId),
            onDeleteSuccess: (date) => handleDeleteAppointment(date),
          }}
          initialServices={services}
        />
      )}
      <SlideNotification
        state={toasterResponse}
        clearStatus={() => setToasterResponse({ message: "", status: 0 })}
      />
    </div>
  );
}
