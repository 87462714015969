// 🎯 Goal: Convert an Date type to a string with YYYY-MM-DD format
export const convertDateToStringYYYYMMDD = (date = new Date()) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
};

// 🎯 Goal: Convert a string with YYYY-MM-DD format to a Date type
export const convertStringYYYYMMDDToDate = (string: string) => {
  const [year, month, day] = string.split("-");
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

// 🎯 Goal: Convert a string with YYYY-MM-DD format to a Date type
export const convertStringDashedToSlice = (string: string) => {
  const [year, month, day] = string.split("-");
  return `${day}/${month}/${year}`;
};
