// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lXRRWOk_VvV03CrJP2G6 {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid #e9e9e9;

  &:last-child {
    border-right: none;
  }
}

.ODd1xiQpnSTYT7JiMe_P {
  min-width: 150px;
}

.u4ljWrk5tcVEBkN8EQNZ {
  position: relative;
  min-height: calc(24 * 64px);
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Calendar/components/CalendarColumn/CalendarColumn.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,OAAO;EACP,+BAA+B;;EAE/B;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;AAC7B","sourcesContent":[".column {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  border-right: 1px solid #e9e9e9;\n\n  &:last-child {\n    border-right: none;\n  }\n}\n\n.columnDay {\n  min-width: 150px;\n}\n\n.columnContent {\n  position: relative;\n  min-height: calc(24 * 64px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column": `lXRRWOk_VvV03CrJP2G6`,
	"columnDay": `ODd1xiQpnSTYT7JiMe_P`,
	"columnContent": `u4ljWrk5tcVEBkN8EQNZ`
};
export default ___CSS_LOADER_EXPORT___;
