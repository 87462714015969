export const getAccessTokenFromLocalStorage = (): string => {
  // 1. Get the access token from the local storage
  const accessToken = localStorage.getItem("access_token");

  // 2. Return the access token
  return accessToken;
};

export const getLoungeIdFromLocalStorage = (): string => {
  // 1. Get the id of the selected lounge of the local storage
  const lounge = localStorage.getItem("selectedLocal");

  // 2. Return the id of the selected lounge
  return JSON.parse(lounge).id;
};
