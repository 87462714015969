import { ApiGetLounge } from "@Services/GET/getLounges.service";

export const getLoungeAvailableHourAdapter = async () => {
  const loungesHour = await ApiGetLounge();
  try {
    const loungesHourData = loungesHour.data.map((data) => {
      return data.idCodeDay === 1
        ? { ...data, idCodeDay: 7 }
        : { ...data, idCodeDay: data.idCodeDay - 1 };
    });

    const loungesAvailable = loungesHourData.map((lounge) => {
      return { ...lounge, desabled: lounge.desabled === 1 ? 0 : 1 };
    });

    return {
      isSuccess: loungesHour.status === 200,
      data: loungesAvailable,
      status: loungesHour.status,
      message: loungesHour.message,
    };
  } catch (error) {
    return {
      isSuccess: false,
      loungesHour: [],
      status: 500,
      message: loungesHour.message,
    };
  }
};
