import { useTimeCalculations } from "../../hooks/useTimeCalculations";
import { useSelector } from "react-redux";
import { RootState } from "@ReduxService/store";
import style from "./style/HourColumn.module.css";
import { useGetDaysAndHoursToRender } from "../../hooks/getDaysAndHoursToShow";

const HourColumn = () => {
  const { calculatePosition, PRE_PADDING } = useTimeCalculations();
  const { daySelected } = useGetDaysAndHoursToRender();

  const selectedDate = new Date(daySelected);
  selectedDate.setDate(selectedDate.getDate() + 1);

  const generateHours = () => {
    const hours = [];
    hours.push({
      hour: -1,
      position: 0,
    });
    for (let i = 0; i < 24; i++) {
      hours.push({
        hour: i,
        position: calculatePosition(i),
      });
    }
    return hours;
  };

  const formatHourToAMPM = (hour: number) => {
    if (hour === -1) return "";
    const ampm = hour >= 12 ? "PM" : "AM";
    const displayHour = hour % 12 || 12;
    return `${displayHour}:00 ${ampm}`;
  };

  return (
    <div className={style.hourColumn}>
      {generateHours().map(({ hour, position }) => (
        <div
          key={`hour-${hour}`}
          className={
            hour === -1
              ? style.hourColumn__prePadding
              : hour === 0
              ? style.hourColumn__hourFirst
              : style.hourColumn__hour
          }
          style={{ position: "absolute", top: `${position}px` }}
        >
          <span className={style.hourText}>{formatHourToAMPM(hour)}</span>
        </div>
      ))}
    </div>
  );
};

export default HourColumn;
