import { useDispatch, useSelector } from "react-redux";
import style from "./Appointment.module.css";
import { RootState } from "@ReduxService/store";
import React, { useState } from "react";
import AppointmentAppliedFilter from "./components/appointmentFilter";
import useAppointmentDetailModal from "./hook/useAppointmentDetailModal";
import { updateAppointmentDetail } from "@ReduxService/slices/appointment/appointmentDetail.slice";
import { useTimeCalculations } from "../../hooks/useTimeCalculations";
import { AppointmentPopover } from "../AppointmentPopover/AppointmentPopover";
import { useManagementModal } from "@Hooks/useManagementModals";
import { setHoveredReserveId } from "@ReduxService/slices/calendar/calendarFilter.slice";

interface AppointmentProps {
  detail: {
    service: {
      hourReserve: string;
      serviceDuration: number;
      employeeName: string;
      serviceName?: string;
    };
    appointment: {
      reserveId: number;
      clientName: string;
      status: number;
      services: Array<{
        hourReserve: string;
        serviceDuration: number;
        employeeName: string;
        serviceName?: string;
      }>;
    };
  };
  width: number;
  left: number;
  day: Date;
}

export default function Appointment({
  detail,
  width,
  left,
  day,
}: AppointmentProps) {
  const dispatch = useDispatch();
  const [isUserOverEvent, setIsUserOverEvent] = useState(false);
  const appointmentRef = React.useRef(null);
  const { HOUR_HEIGHT, PRE_PADDING } = useTimeCalculations();

  // Obtener el ID de la reserva que está en hover del estado global
  const hoveredReserveId = useSelector(
    (state: RootState) => state.calendarFilter.hoveredReserveId
  );

  // Determinar si esta tarjeta debe estar resaltada
  const isHighlighted = hoveredReserveId === detail.appointment.reserveId;

  const selectedStates = useSelector(
    (state: RootState) => state.calendarFilter.appointmentStates
  );

  const appointmentStates = useSelector(
    (state: RootState) => state.appointmentStates.states
  );

  const currentState = appointmentStates.find(
    (state) => state.id === detail.appointment.status
  );
  const stateColor = currentState ? currentState.color : "#6B7280";
  const textColor = currentState?.id === 7 ? "#45348e" : "#fff";

  // Extraer hora y minutos del servicio actual
  const [hour, minutes] = detail.service.hourReserve.split(":").map(Number);

  // Calcular posición vertical
  const topPosition = (hour * 60 + minutes) * (HOUR_HEIGHT / 60) + PRE_PADDING;

  // Altura basada en la duración del servicio individual
  const height = (detail.service.serviceDuration / 60) * HOUR_HEIGHT;

  const { openEditAppointmentModal } = useManagementModal();

  // Manejadores de hover
  const handleMouseEnter = () => {
    setIsUserOverEvent(true);
    dispatch(setHoveredReserveId(detail.appointment.reserveId));
  };

  const handleMouseLeave = () => {
    setIsUserOverEvent(false);
    dispatch(setHoveredReserveId(null));
  };

  useAppointmentDetailModal({
    appointmentRef,
    setIsUserOverEvent,
    openEditAppointmentModal,
    detail: {
      status: detail.appointment.status,
      reserveId: String(detail.appointment.reserveId),
      clientId: "",
      clientName: detail.appointment.clientName,
      clientPhone: "",
      clientEmail: "",
      services: detail.appointment.services.map((service) => ({
        serviceName: service.serviceName,
        priceServiceName: service.serviceName || "",
      })),
      totalPrice: 0,
      totalDuration: 0,
      appointmentComment: "",
    },
    day,
    selectedStates,
  });

  return (
    <AppointmentAppliedFilter appointmentDetail={detail.appointment}>
      <button
        className={`${style.Appointment} ${
          isHighlighted ? style.highlighted : ""
        } `}
        style={{
          left: `${left}%`,
          width: `${width}%`,
          height: `${height}px`,
          top: `${topPosition}px`,
          backgroundColor: stateColor,
          overflow: "hidden",
          padding: "4px 8px",
          color: textColor,
        }}
        ref={appointmentRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() =>
          dispatch(
            updateAppointmentDetail({
              appointmentDetail: detail,
            })
          )
        }
      >
        <div className={style.appointmentContent}>
          <div className={style.clientName}>
            {detail.appointment.clientName}
          </div>
          <div className={style.timeInfo}>{detail.service.hourReserve}</div>
        </div>
      </button>
      <AppointmentPopover
        isOpen={isUserOverEvent}
        anchorEl={appointmentRef.current}
        onClose={() => setIsUserOverEvent(false)}
        detail={detail}
        currentState={currentState}
        textColor={textColor}
      />
    </AppointmentAppliedFilter>
  );
}
