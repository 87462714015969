import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppointmentState } from "@Services/GET/getAppointmentStates.service";

export interface IAppointmentStatesState {
  states: IAppointmentState[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: IAppointmentStatesState = {
  states: [],
  status: "idle",
  error: null,
};

const appointmentStatesSlice = createSlice({
  name: "appointmentStates",
  initialState,
  reducers: {
    loadAppointmentStates: (
      state,
      action: PayloadAction<IAppointmentState[]>
    ) => {
      state.states = action.payload;
      state.status = "succeeded";
    },
    setLoadingStatus: (state) => {
      state.status = "loading";
    },
    setErrorStatus: (state, action: PayloadAction<string>) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const { loadAppointmentStates, setLoadingStatus, setErrorStatus } =
  appointmentStatesSlice.actions;
export default appointmentStatesSlice.reducer;
