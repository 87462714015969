// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zxP6uFbXsZoatNHx52s5 {
  position: relative;
  display: inline-block;
}

.DxzdH2zafSQrFQhTnxTZ {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid #dadada;
  border-radius: 5px;
  background: white;
  cursor: pointer;
  font-size: var(--font-size-reading);
  color: #212121;
  height: 40px;
  transition: all 150ms ease-in-out;
}

.DxzdH2zafSQrFQhTnxTZ:hover {
  border-color: #d6cdf9;
}

.DxzdH2zafSQrFQhTnxTZ.bRx0sIOx4gqJg1VYoICb {
  border-color: #937cf4;
}

.sWqbjuitpys9LvpWVMy0 {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 120px;
  overflow: hidden;
}

.Bc5OKuk51BGUFW0dEIVw {
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  color: #212121;
}

.Bc5OKuk51BGUFW0dEIVw:hover {
  background-color: #f9fafb;
}

.Bc5OKuk51BGUFW0dEIVw.Tqv0k5tfZuf5tM5mOaVN {
  color: #45348e;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/CalendarHeader/components/ViewModeSelector.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,mCAAmC;EACnC,cAAc;EACd,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,6CAA6C;EAC7C,aAAa;EACb,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".viewModeContainer {\n  position: relative;\n  display: inline-block;\n}\n\n.viewModeButton {\n  display: flex;\n  align-items: center;\n  padding: 8px 16px;\n  border: 1px solid #dadada;\n  border-radius: 5px;\n  background: white;\n  cursor: pointer;\n  font-size: var(--font-size-reading);\n  color: #212121;\n  height: 40px;\n  transition: all 150ms ease-in-out;\n}\n\n.viewModeButton:hover {\n  border-color: #d6cdf9;\n}\n\n.viewModeButton.active {\n  border-color: #937cf4;\n}\n\n.dropdown {\n  position: absolute;\n  top: 100%;\n  right: 0;\n  margin-top: 4px;\n  background: white;\n  border: 1px solid #e5e7eb;\n  border-radius: 8px;\n  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);\n  z-index: 1000;\n  min-width: 120px;\n  overflow: hidden;\n}\n\n.option {\n  width: 100%;\n  padding: 8px 16px;\n  text-align: left;\n  border: none;\n  background: none;\n  cursor: pointer;\n  color: #212121;\n}\n\n.option:hover {\n  background-color: #f9fafb;\n}\n\n.option.selected {\n  color: #45348e;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewModeContainer": `zxP6uFbXsZoatNHx52s5`,
	"viewModeButton": `DxzdH2zafSQrFQhTnxTZ`,
	"active": `bRx0sIOx4gqJg1VYoICb`,
	"dropdown": `sWqbjuitpys9LvpWVMy0`,
	"option": `Bc5OKuk51BGUFW0dEIVw`,
	"selected": `Tqv0k5tfZuf5tM5mOaVN`
};
export default ___CSS_LOADER_EXPORT___;
