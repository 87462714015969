import axios from "axios";
import { authorizeTheCallToApi } from "../API/authorization";
import { GET_APPOINTMENT_WEEK_API } from "@Models/services/lounge.services";
import { convertDateToStringYYYYMMDD } from "@Utilities/dates/convertDatesBetweenFormat";
import {
  IGetAppointmentsResponse,
  IGetAppointmentsService,
} from "@Models/types/api";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const apiGetAppointments = async ({
  fromDate,
  toDate,
}: IGetAppointmentsService): Promise<IGetAppointmentsResponse> => {
  const carga_reservas = 71;
  trackUserAction(carga_reservas);
  // 1. Validate the fromDate and toDate
  try {
    if (!fromDate || !toDate)
      throw new Error("The fromDate and toDate are required");

    // 2. Generate the params
    const PARAMS = {
      fromDate: convertDateToStringYYYYMMDD(fromDate),
      toDate: convertDateToStringYYYYMMDD(toDate),
    };

    // 3. Generate the authorization
    const AUTHORIZATION = { ...authorizeTheCallToApi() };

    // 4. Generate the config
    const CONFIG = {
      params: PARAMS,
      ...AUTHORIZATION,
    };

    // 5. Get the appointments of the week
    const response = await axios.get(GET_APPOINTMENT_WEEK_API, CONFIG);

    // 6. Return the data of the response
    return response.data;
  } catch (error) {
    return {
      status: 500,
      data: [],
      message: error.message,
    };
  }
};
