import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@ReduxService/store";
import {
  changeViewMode,
  changeDaysToShow,
} from "@ReduxService/slices/calendar/calendarView.slice";
import style from "./ViewModeSelector.module.css";

export const ViewModeSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const currentViewMode = useSelector(
    (state: RootState) => state.calendarView.viewMode
  );
  const employees = useSelector(
    (state: RootState) => state.appointments.employeesCached
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleViewModeChange = (mode: "week" | "day") => {
    if (mode === "week") {
      dispatch(changeDaysToShow(7));
    } else {
      dispatch(changeDaysToShow(employees.length || 1));
    }
    dispatch(changeViewMode(mode));
    setIsOpen(false);
  };

  return (
    <div className={style.viewModeContainer} ref={dropdownRef}>
      <button
        className={`${style.viewModeButton} ${isOpen ? style.active : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {currentViewMode === "week" ? "Semana" : "Día"}
      </button>

      {isOpen && (
        <div className={style.dropdown}>
          <button
            className={`${style.option} ${
              currentViewMode === "day" ? style.selected : ""
            }`}
            onClick={() => handleViewModeChange("day")}
          >
            Día
          </button>
          <button
            className={`${style.option} ${
              currentViewMode === "week" ? style.selected : ""
            }`}
            onClick={() => handleViewModeChange("week")}
          >
            Semana
          </button>
        </div>
      )}
    </div>
  );
};
