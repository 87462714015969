import App from "@Pages/App.pages";
import { store } from "@ReduxService/store";
import { Provider } from "react-redux";
import "./interceptors/timeout.interceptor";

export default function Root(props) {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}
