import { ILimitHours } from "@Models/types/Hours";
import { ISchedule } from "@Models/types/Schedule";

// 🎯 Goal: get the maximum and minimum hour of the lounge
export function getTheLimitHour(schedules: ISchedule[]): ILimitHours | null {
  // If the schedules are not an array or the array is empty, return null
  if (!Array.isArray(schedules) || schedules.length === 0) {
    return null;
  }

  // Get the minimum and maximum hours
  let minOpeningHour = "23:59:59";
  let maxClosingHour = "00:00:00";

  // Iterate over the schedules to get the minimum and maximum hours
  schedules.forEach((schedule) => {
    const openingTime = parseTime(schedule.opening);
    const closingTime = parseTime(schedule.closing);

    // Update the minimum hours if the opening time is less than the current minimum
    if (openingTime < minOpeningHour) {
      minOpeningHour = openingTime;
    }

    // Update the maximum hours if the closing time is greater than the current maximum
    if (closingTime > maxClosingHour) {
      maxClosingHour = closingTime;
    }
  });

  // Return the minimum and maximum hours
  return { minOpeningHour, maxClosingHour };
}

// 🎯 Goal: Parse the time string to a valid time format
// Parse the time string to a valid time format
function parseTime(timeStr: string): string {
  // Get the hour, minute, and second from the time string
  const [hour, minute, second] = timeStr.split(":").map(Number);

  // Return the time in the format HH:MM:SS
  return `${hour < 10 ? "0" : ""}${hour}:${minute < 10 ? "0" : ""}${minute}:${
    second < 10 ? "0" : ""
  }${second}`;
}
