import { ISchedule } from "@Models/types/Schedule";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IFetchStatus {
  status: "loading" | "succeeded" | "failed";
  error: string | null;
}

// Define the state of the slice
export interface ICalendarViewState {
  daysToShow: number;
  calendarMaxHours: string;
  calendarMinHours: string;
  hoursToShow: number;
  hours: ISchedule[];
  hoursFetchStatus: IFetchStatus;
  viewMode: "week" | "day";
}

interface IChangeMaxAndMinHoursPayload {
  max: string;
  min: string;
}

// Define the initial state
const DEFAULT_STATE: ICalendarViewState = {
  daysToShow: 7,
  hoursToShow: 0,
  calendarMaxHours: "",
  calendarMinHours: "",
  hours: [],
  hoursFetchStatus: {
    status: "loading",
    error: null,
  },
  viewMode: "week",
};

// Create the slice
const calendarViewSlice = createSlice({
  name: "calendarView",
  initialState: DEFAULT_STATE,
  reducers: {
    // Goal: Change the days to show in the calendar
    changeDaysToShow: (state, action: PayloadAction<number>) => {
      state.daysToShow = action.payload;
    },

    // Goal: Change the max and min hours to show in the calendar
    changeMaxAndMinHours: (
      state,
      action: PayloadAction<IChangeMaxAndMinHoursPayload>
    ) => {
      state.calendarMaxHours = action.payload.max;
      state.calendarMinHours = action.payload.min;
    },

    // Goal: Set the hours to show in the calendar
    setHoursToShow: (state, action: PayloadAction<number>) => {
      state.hoursToShow = action.payload;
    },

    // Goal: Set the hours in the calendar
    setHours: (state, action: PayloadAction<ISchedule[]>) => {
      state.hours = action.payload;
    },

    // Goal: Set the hours fetch status
    setHoursFetchStatus: (state, action: PayloadAction<IFetchStatus>) => {
      state.hoursFetchStatus = action.payload;
    },

    // Goal: Change the view mode of the calendar
    changeViewMode: (state, action: PayloadAction<"week" | "day">) => {
      state.viewMode = action.payload;
    },
  },
});

export const {
  changeDaysToShow,
  changeMaxAndMinHours,
  setHoursToShow,
  setHours,
  setHoursFetchStatus,
  changeViewMode,
} = calendarViewSlice.actions;

export default calendarViewSlice.reducer as (
  state: ICalendarViewState,
  action: PayloadAction<any>
) => ICalendarViewState;
