import { useEffect } from "react";
// @ts-ignore
import { validateModuleAccess } from "@viuti/recursos";
import { UPSERT_APPOINTMENT_UUID } from "@Models/constants/securityAccess";
import { useSelector } from "react-redux";
import { RootState } from "@ReduxService/store";

interface IAppointmentDetailModal {
  appointmentRef: React.RefObject<HTMLButtonElement>;
  setIsUserOverEvent: (isUserOverEvent: boolean) => void;
  openEditAppointmentModal: (appointment: any) => void;
  detail: {
    status: number;
    reserveId: string;
    clientId: string;
    clientName: string;
    clientPhone: string;
    clientEmail: string;
    services: Array<{
      serviceName?: string;
      priceServiceName: string;
    }>;
    totalPrice: number;
    totalDuration: number;
    appointmentComment: string;
  };
  day: Date;
  selectedStates: number[];
}

const useAppointmentDetailModal = ({
  appointmentRef,
  setIsUserOverEvent,
  openEditAppointmentModal,
  detail,
  day,
  selectedStates,
}: IAppointmentDetailModal) => {
  const viewOnlyCustomer = useSelector(
    (state: RootState) => state.calendarFilter.viewOnlyCustomer
  );
  const attendedByEmployee = useSelector(
    (state: RootState) => state.calendarFilter.attendedByEmployee
  );

  useEffect(() => {
    if (appointmentRef.current) {
      appointmentRef.current.addEventListener("mouseenter", () => {
        setIsUserOverEvent(true);
      });
      appointmentRef.current.addEventListener("mouseleave", () => {
        setIsUserOverEvent(false);
      });
      appointmentRef.current.addEventListener("click", () => {
        setIsUserOverEvent(false);
        if (
          selectedStates.length === 0 ||
          selectedStates.includes(1) ||
          selectedStates.includes(detail.status)
        ) {
          validateModuleAccess(UPSERT_APPOINTMENT_UUID) &&
            openEditAppointmentModal({
              reserveId: detail.reserveId,
              customer: {
                _id: detail.clientId,
                name: detail.clientName,
                phone: detail.clientPhone,
                email: detail.clientEmail,
              },
              appointments: detail.services.map((s) => ({
                ...s,
                serviceName: s.serviceName + ": " + s.priceServiceName,
              })),
              totalPrice: detail.totalPrice,
              totalDuration: detail.totalDuration,
              appointmentComment: detail.appointmentComment,
              status: detail.status,
              day,
            });
        }
      });
    }

    return () => {
      if (appointmentRef.current) {
        appointmentRef.current.removeEventListener("mouseenter", () => {
          setIsUserOverEvent(true);
        });
        appointmentRef.current.removeEventListener("mouseleave", () => {
          setIsUserOverEvent(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        appointmentRef.current.removeEventListener("click", () => {
          setIsUserOverEvent(false);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentRef, viewOnlyCustomer, selectedStates, attendedByEmployee]);
};

export default useAppointmentDetailModal;
