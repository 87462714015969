import {
  addNewCustomerToTheAppointment,
  addNewItemToTheAppointment,
  addReserveId,
  resetToInitialState,
} from "@ReduxService/slices/modals/newAppointment.slice";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { fillNumberWithZeros } from "@Utilities/numbers/fillNumberWithZeros";
import { RootState } from "@ReduxService/store";
import { setShowFormEditReservation } from "@ReduxService/slices/modals/appointmentModal.slice";
interface Customer {
  _id: number;
  name: string;
  phone?: string;
  email?: string;
}

interface Appointment {
  reserveDetailId: number;
  serviceId: number;
  serviceName: string;
  priceServiceId: number;
  priceServiceName: string;
  priceService: number;
  packId: number | null;
  packName: string | null;
  employeeId: number;
  employeeName: string;
  employeePhoto: string | null;
  serviceDuration: number;
  hourReserve: string;
}

interface Detail {
  reserveId: number;
  day: Date;
  customer: Customer;
  appointments: Appointment[];
  totalPrice: number;
  totalDuration?: number;
  appointmentComment?: string;
}

export function useManagementModal() {
  const dispatch = useDispatch();

  const showFormEditReservation = useSelector(
    (state: RootState) => state.appointmentModal.showFormEditReservation
  );

  const openEditAppointmentModal = (detail: Detail) => {
    // Here you can set the detail of the appointment to edit
    dispatch(
      addNewCustomerToTheAppointment({
        _id: detail.customer._id,
        name: detail.customer.name,
        email: detail.customer.email,
        phone: detail.customer.phone,
      })
    );

    let totalDuration = 0;
    let totalPrice = 0;
    detail.appointments.forEach((appointment) => {
      totalDuration += appointment.serviceDuration;
      totalPrice += appointment.priceService;

      dispatch(
        addNewItemToTheAppointment({
          uuid: uuidv4(),
          type: "SERVICE",
          name: appointment.serviceName,
          _id: appointment.serviceId,
          employee: {
            _id: appointment.employeeId,
            name: appointment.employeeName,
          },
          price: appointment.priceService,
          duration: appointment.serviceDuration,
          date:
            detail.day.getFullYear() +
            "-" +
            fillNumberWithZeros(detail.day.getMonth() + 1, 2) +
            "-" +
            fillNumberWithZeros(detail.day.getDate(), 2),
          hour: appointment.hourReserve,
          canEditDateAndHour: false,
          reserveDetailId: appointment.reserveDetailId,
        })
      );
    });

    dispatch(addReserveId(detail.reserveId));
    dispatch(setShowFormEditReservation(Number(detail.reserveId)));
  };

  const closeEditAppointmentModal = () => {
    dispatch(resetToInitialState());
    dispatch(setShowFormEditReservation(null));
  };

  return {
    closeEditAppointmentModal,
    showFormEditReservation,
    setShowFormEditReservation: (value: number | null) =>
      dispatch(setShowFormEditReservation(value)),
    openEditAppointmentModal,
  };
}
