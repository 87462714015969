import { getAppointmentsAndUpdateCache } from "@Hooks/useGetAppointments";
import {
  changeShowEventsOnTheDay,
  changeTheClientSearchInput,
} from "@ReduxService/slices/calendar/calendarFilter.slice";
import { RootState } from "@ReduxService/store";
import {
  convertStringYYYYMMDDToDate,
  convertDateToStringYYYYMMDD,
} from "@Utilities/dates/convertDatesBetweenFormat";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useInitialState = () => {
  const [searchClient, setSearchClient] = useState("");
  // Redux
  const dispatch = useDispatch();
  const appointmentsCached = useSelector(
    (state: RootState) => state.appointments.appointmentWeekCached
  );

  // get the selectors from the state
  const calendarFilterSelector = useSelector(
    (state: RootState) => state.calendarFilter
  );

  const dateFilter = calendarFilterSelector.showEventsOnTheDay;

  // Dispatch the actions to change the redux state
  const changeDateFilter = (value) => {
    dispatch(changeShowEventsOnTheDay(value));
    getAppointmentsAndUpdateCache({
      dispatch,
      appointmentsCached,
      day: convertStringYYYYMMDDToDate(value),
    });
  };

  const incrementDateFilter = () => {
    const currentDate = convertStringYYYYMMDDToDate(dateFilter);
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    const nextDateString = convertDateToStringYYYYMMDD(nextDate);
    changeDateFilter(nextDateString);
  };

  const decrementDateFilter = () => {
    const currentDate = convertStringYYYYMMDDToDate(dateFilter);
    const prevDate = new Date(currentDate);
    prevDate.setDate(currentDate.getDate() - 1);
    const prevDateString = convertDateToStringYYYYMMDD(prevDate);
    changeDateFilter(prevDateString);
  };

  // function to increment 7 days to the date filter without receiving any parameter
  const incrementDateFilterWeek = () => {
    const currentDate = convertStringYYYYMMDDToDate(dateFilter);
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 7);
    const nextDateString = convertDateToStringYYYYMMDD(nextDate);
    changeDateFilter(nextDateString);
  };

  // function to decrement 7 days to the date filter without receiving any parameter
  const changeFilterToPreviousWeek = () => {
    const currentDate = convertStringYYYYMMDDToDate(dateFilter);
    const prevDate = new Date(currentDate);
    prevDate.setDate(currentDate.getDate() - 7);
    const prevDateString = convertDateToStringYYYYMMDD(prevDate);
    changeDateFilter(prevDateString);
  };

  const changeSearchInput = (value) => {
    setSearchClient(value);
    dispatch(changeTheClientSearchInput(value));
  };

  // Return
  return {
    dateFilter,
    changeDateFilter,
    incrementDateFilter,
    decrementDateFilter,
    searchClient,
    setSearchClient: changeSearchInput,
    incrementDateFilterWeek,
    changeFilterToPreviousWeek,
  };
};

export default useInitialState;
