import { useState, useRef, useEffect } from "react";
import useInitialState from "../CalendarFilter/hooks/initialStates";
import { StateFilterMenu } from "../CalendarFilter/components/StateFilterMenu";
import { Reload } from "../CalendarFilter/components/Reload/Reload";
import SearchWithColoredBorderBuilder from "@Components/resourcesBuilder/SearchWithColoredBorder";
import style from "./CalendarHeader.module.css";
import { CustomDateSelector } from "./components/CustomDateSelector";
// @ts-ignore
import { ButtonWithoutIcon } from "@viuti/recursos";
import { UPSERT_APPOINTMENT_UUID } from "@Models/constants/securityAccess";
import { IoFilter } from "react-icons/io5";
import useWidth from "@Hooks/useWidth";
import { ViewModeSelector } from "./components/ViewModeSelector";
import { EmployeeFilterMenu } from "../CalendarFilter/components/EmployeeFilterMenu";

interface ICalendarHeader {
  openFormNewReservation: () => void;
  validateModuleAccess: (uuid: string, isAdmin: boolean) => boolean;
}

export const CalendarHeader: React.FC<ICalendarHeader> = ({
  openFormNewReservation,
  validateModuleAccess,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const filterContainerRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const screenWidth = useWidth();
  const mobileWidthBreakpoint = 768;

  const { searchClient, setSearchClient } = useInitialState();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterContainerRef.current &&
        !filterContainerRef.current.contains(event.target as Node) &&
        !filterButtonRef.current?.contains(event.target as Node)
      ) {
        setShowFilters(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openAppointmentModal = () => {
    validateModuleAccess(UPSERT_APPOINTMENT_UUID, false) &&
      openFormNewReservation();
  };

  return (
    <header className={style.header}>
      <div className={style.mainControls}>
        <div className={style.leftControls}>
          {screenWidth > mobileWidthBreakpoint && (
            <ButtonWithoutIcon
              handleClick={openAppointmentModal}
              isDisabled={false}
              isLoading={false}
              isPrimary
              textBttn="Nueva Reserva"
              width={"auto"}
            />
          )}
          <CustomDateSelector />
          <Reload />
        </div>
        <div className={style.rightControls}>
          <ViewModeSelector />
          <button
            ref={filterButtonRef}
            className={`${style.filterToggle} ${
              showFilters ? style.active : ""
            }`}
            onClick={() => setShowFilters(!showFilters)}
          >
            <IoFilter />
            {screenWidth > mobileWidthBreakpoint && "Filtros"}
          </button>
        </div>
      </div>

      {showFilters && (
        <div className={style.expandedFilters} ref={filterContainerRef}>
          <div className={style.filterRow}>
            <SearchWithColoredBorderBuilder
              placeholder="Buscar por DNI o nombre del cliente"
              value={searchClient}
              setValue={setSearchClient}
              allData={[]}
              showResult={false}
              disabled={false}
            />

            <EmployeeFilterMenu />

            <StateFilterMenu />
          </div>
        </div>
      )}
    </header>
  );
};
