import { useMemo } from "react";
import style from "./UnavailableHours.module.css";
import { useTimeCalculations } from "../../hooks/useTimeCalculations";

interface TimeBlock {
  start: number;
  duration: number;
}

interface UnavailableHoursProps {
  availableHours: {
    opening: string;
    closing: string;
  }[];
}

export const UnavailableHours = ({ availableHours }: UnavailableHoursProps) => {
  const { HOUR_HEIGHT, PRE_PADDING } = useTimeCalculations();

  const unavailableBlocks = useMemo(() => {
    const blocks: TimeBlock[] = [];

    // Ordenar las horas disponibles
    const sortedHours = [...availableHours].sort((a, b) => {
      return parseInt(a.opening) - parseInt(b.opening);
    });

    // Calcular bloques no disponibles
    sortedHours.forEach((period, index) => {
      const [startHour] = period.opening.split(":").map(Number);
      const [endHour] = period.closing.split(":").map(Number);

      // Si hay un espacio antes del primer bloque disponible
      if (index === 0 && startHour > 0) {
        blocks.push({
          start: 0,
          duration: startHour,
        });
      }

      // Si hay un espacio entre bloques disponibles
      if (index > 0) {
        const [prevEndHour] = sortedHours[index - 1].closing
          .split(":")
          .map(Number);
        if (startHour > prevEndHour) {
          blocks.push({
            start: prevEndHour,
            duration: startHour - prevEndHour,
          });
        }
      }

      // Si hay un espacio después del último bloque disponible
      if (index === sortedHours.length - 1 && endHour < 24) {
        blocks.push({
          start: endHour,
          duration: 24 - endHour,
        });
      }
    });

    return blocks;
  }, [availableHours]);

  return (
    <>
      {unavailableBlocks.map((block, index) => (
        <div
          key={index}
          className={style.unavailableBlock}
          style={{
            top: `${block.start * HOUR_HEIGHT + PRE_PADDING}px`,
            height: `${block.duration * HOUR_HEIGHT}px`,
          }}
        />
      ))}
    </>
  );
};
