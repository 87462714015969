// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OpDrqZMVwzh0vve3GIKl {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NPF0Bj7mSY_yPob4dzd4 {
  background-color: #f5f5f5;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.NPF0Bj7mSY_yPob4dzd4 svg {
  color: #6b7280;
  width: 20px;
  height: 20px;
}

.NPF0Bj7mSY_yPob4dzd4:hover {
  background-color: #e5e7eb;
}

.NPF0Bj7mSY_yPob4dzd4:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

.DmVc5kcQO_JYJmj43C9I {
  animation: eq6CLFQtq1PHVvo2XEyJ 2s linear infinite;
}

@keyframes eq6CLFQtq1PHVvo2XEyJ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/CalendarFilter/components/Reload/Reload.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,6CAA6C;AAC/C;;AAEA;EACE,kDAAoC;AACtC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".reloadContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.reload {\n  background-color: #f5f5f5;\n  border: none;\n  border-radius: 50%;\n  width: 32px;\n  height: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.reload svg {\n  color: #6b7280;\n  width: 20px;\n  height: 20px;\n}\n\n.reload:hover {\n  background-color: #e5e7eb;\n}\n\n.reload:focus {\n  outline: none;\n  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);\n}\n\n.rotating {\n  animation: rotate 2s linear infinite;\n}\n\n@keyframes rotate {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reloadContainer": `OpDrqZMVwzh0vve3GIKl`,
	"reload": `NPF0Bj7mSY_yPob4dzd4`,
	"rotating": `DmVc5kcQO_JYJmj43C9I`,
	"rotate": `eq6CLFQtq1PHVvo2XEyJ`
};
export default ___CSS_LOADER_EXPORT___;
