import { apiGetAppointmentStates } from "@Services/GET/getAppointmentStates.service";
import { loadAppointmentStates } from "@ReduxService/slices/appointment/appointmentStates.slice";

export const getAppointmentStatesAdapter = async (dispatch) => {
  try {
    const response = await apiGetAppointmentStates();

    if (response.status === 200) {
      dispatch(loadAppointmentStates(response.data));
    }

    return {
      isSuccess: response.status === 200,
      data: response.data,
      message: response.message,
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: [],
      message: "Error al obtener los estados de las citas",
    };
  }
};
