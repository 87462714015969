import { RootState } from "@ReduxService/store";
import { useSelector } from "react-redux";

const AppointmentAppliedFilter = ({ appointmentDetail, children }) => {
  const selectedStates = useSelector(
    (state: RootState) => state.calendarFilter.appointmentStates
  );
  const showAllStates = useSelector(
    (state: RootState) => state.calendarFilter.showAllStates
  );
  const showOnlyCustomer = useSelector(
    (state: RootState) => state.calendarFilter.viewOnlyCustomer
  );
  const selectedEmployees = useSelector(
    (state: RootState) => state.calendarFilter.attendedByEmployee
  );

  // Si showAllStates es true, mostramos todas las citas
  if (!showAllStates && !selectedStates.includes(appointmentDetail.status)) {
    return null;
  }

  // Filtros existentes para cliente
  if (
    showOnlyCustomer !== "" &&
    !appointmentDetail.clientName
      .toLowerCase()
      .includes(showOnlyCustomer.toLowerCase())
  )
    return null;

  // Filtro de empleados
  if (
    !selectedEmployees.includes("Todos los empleados") &&
    !appointmentDetail.services.some((service) =>
      selectedEmployees.some(
        (emp) => service.employeeName.toLowerCase() === emp.toLowerCase()
      )
    )
  ) {
    return null;
  }

  return children;
};

export default AppointmentAppliedFilter;
