import { ROUTES } from "@Models/routes.models";
import AppointmentRoutes from "@Routes/app.routes";
import { BrowserRouter as Router } from "react-router-dom";

export default function App() {
  return (
    <Router basename={ROUTES.MAIN_APPOINTMENT_ROUTE}>
      <AppointmentRoutes />
    </Router>
  );
}
