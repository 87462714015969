import { useState, useEffect, useRef } from "react";
import style from "../styles/StateFilterMenu.module.css";
//@ts-ignore
import { Icon } from "@viuti/recursos";
import { FilterIcon } from "@Models/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAppointmentStateFilter,
  setShowAllStates,
} from "@ReduxService/slices/calendar/calendarFilter.slice";
import { RootState } from "@ReduxService/store";

export const StateFilterMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStates, setSelectedStates] = useState<number[]>([]);
  const menuRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const showAllStates = useSelector(
    (state: RootState) => state.calendarFilter.showAllStates
  );
  const appointmentStates = useSelector(
    (state: RootState) => state.appointmentStates.states
  );
  // Manejador para cerrar el menú cuando se hace clic fuera
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleShowAllStates = () => {
    const newShowAllStates = !showAllStates;
    setShowAllStates(newShowAllStates);
    if (newShowAllStates) {
      setSelectedStates([]);
      dispatch(changeAppointmentStateFilter([]));
    }
    dispatch(setShowAllStates(newShowAllStates));
  };

  const handleStateChange = (stateId: number) => {
    let newSelectedStates: number[];

    if (selectedStates.includes(stateId)) {
      newSelectedStates = selectedStates.filter((id) => id !== stateId);
    } else {
      newSelectedStates = [...selectedStates, stateId];
    }

    if (showAllStates) {
      setShowAllStates(false);
      dispatch(setShowAllStates(false));
    }

    if (newSelectedStates.length === 0) {
      setShowAllStates(true);
      dispatch(setShowAllStates(true));
    }

    setSelectedStates(newSelectedStates);
    dispatch(changeAppointmentStateFilter(newSelectedStates));
  };

  return (
    <div className={style.menuContainer} ref={menuRef}>
      <button className={style.filterButton} onClick={() => setIsOpen(!isOpen)}>
        <span>Estados</span>
        <Icon path={FilterIcon} size={16} color="#45348e" />
      </button>

      {isOpen && (
        <div className={style.menuDropdown}>
          <label className={`${style.menuItem} ${style.allStatesOption}`}>
            <input
              type="checkbox"
              checked={showAllStates}
              onChange={handleShowAllStates}
            />
            <span>Todos los estados</span>
          </label>

          <div className={style.stateDivider} />

          {appointmentStates
            .filter((state) => state.id !== -1)
            .map((state) => (
              <label key={state.id} className={style.menuItem}>
                <input
                  type="checkbox"
                  checked={selectedStates.includes(state.id)}
                  onChange={() => handleStateChange(state.id)}
                  // disabled={showAllStates}
                />
                <span
                  className={style.stateColor}
                  style={{ backgroundColor: state.color }}
                />
                <span className={style.stateName}>{state.name}</span>
              </label>
            ))}
        </div>
      )}
    </div>
  );
};
