// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aDlCzIKgXsg9n1nkAJwe {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid var(--CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE);
  border-radius: 0.5rem;

  height: calc(24 * 64px);
  overflow: hidden;
}

.v5QCwfqgQrzlfrNnKZTA {
  display: flex;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.a8djwSEWdxfyphCFLvQ6 {
  display: flex;
  flex: 1;
  position: relative;
  min-height: calc(24 * 64px + 32px);
  -webkit-overflow-scrolling: touch;
}

.QFv2mjawFSI9sbTibdPB {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #878a8e;
  opacity: 0.15;
  left: 0;
  right: 0;
  pointer-events: none;
}

.oorAW7SVK0j_rCTi7pMf {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #cf0000;
  /* opacity: 0.8; */
  left: 0;
  right: 0;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #cf0000;
    border-radius: 50%;
    top: -4px;
    left: -5.5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Calendar/styles/Calendar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,qEAAqE;EACrE,qBAAqB;;EAErB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gBAAgB;;EAEhB;IACE,aAAa;EACf;AACF;;AAEA;EACE,aAAa;EACb,OAAO;EACP,kBAAkB;EAClB,kCAAkC;EAClC,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,OAAO;EACP,QAAQ;EACR,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,UAAU;;EAEV;IACE,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,SAAS;IACT,YAAY;EACd;AACF","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  background-color: #ffffff;\n  border: 1px solid var(--CARPENTER-VIUTI-CORE-COLOR-DARK-ON-BASEPLATE);\n  border-radius: 0.5rem;\n\n  height: calc(24 * 64px);\n  overflow: hidden;\n}\n\n.calendar__container {\n  display: flex;\n  overflow-y: auto;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}\n\n.dayColumnContainer {\n  display: flex;\n  flex: 1;\n  position: relative;\n  min-height: calc(24 * 64px + 32px);\n  -webkit-overflow-scrolling: touch;\n}\n\n.hourGuide {\n  position: absolute;\n  width: 100%;\n  height: 1px;\n  background-color: #878a8e;\n  opacity: 0.15;\n  left: 0;\n  right: 0;\n  pointer-events: none;\n}\n\n.timeMarker {\n  position: absolute;\n  width: 100%;\n  height: 2px;\n  background-color: #cf0000;\n  /* opacity: 0.8; */\n  left: 0;\n  right: 0;\n  z-index: 2;\n\n  &::before {\n    content: \"\";\n    position: absolute;\n    width: 10px;\n    height: 10px;\n    background-color: #cf0000;\n    border-radius: 50%;\n    top: -4px;\n    left: -5.5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `aDlCzIKgXsg9n1nkAJwe`,
	"calendar__container": `v5QCwfqgQrzlfrNnKZTA`,
	"dayColumnContainer": `a8djwSEWdxfyphCFLvQ6`,
	"hourGuide": `QFv2mjawFSI9sbTibdPB`,
	"timeMarker": `oorAW7SVK0j_rCTi7pMf`
};
export default ___CSS_LOADER_EXPORT___;
