import style from "./ServiceTimeline.module.css";

interface ServiceTimelineProps {
  services: Array<{
    hourReserve: string;
    serviceDuration: number;
    employeeName: string;
    serviceName?: string;
  }>;
  currentServiceHour: string;
}

export const ServiceTimeline = ({
  services,
  currentServiceHour,
}: ServiceTimelineProps) => {
  return (
    <div className={style.timeline}>
      {services.map((service, index) => (
        <div
          key={index}
          className={`${style.timelineItem} ${
            service.hourReserve === currentServiceHour ? style.active : ""
          }`}
        >
          <div className={style.timelineDot} />
          <div className={style.timelineContent}>
            <span className={style.timelineTime}>{service.hourReserve}</span>
            {"-"}
            <span className={style.timelineService}>
              {service.serviceName || "Servicio sin nombre"},
            </span>
            <span className={style.timelineEmployee}>
              {service.employeeName}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
