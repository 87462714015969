import { getServices_service } from "@Services/getServices.serviceV2";
import { formatString } from "@Utilities/client/utilities/formatString";
export interface Service {
  idPrice: number;
  price: number;
  namePrice: string;
  duration: number;
  employees: {
    idEmployee: number;
    nameEmployee: string;
  }[];
}

export const getServices_adapter = async (): Promise<{
  isSuccess: boolean;
  status: number;
  message: string;
  data: Service[];
}> => {
  const response = await getServices_service();
  if (!response.isSuccess) {
    return {
      isSuccess: false,
      status: response.status,
      message: response.message,
      data: [],
    };
  }

  const servicesAdapt = response.data.flatMap((service) =>
    service.prices.map((price) => ({
      idPrice: price.idPrice,
      price: price.totalPrice,
      namePrice: `${service.nameService}: ${price.namePrice}`,
      duration: price.duration,
      employees: service.employees.map((emp) => ({
        idEmployee: emp.idEmployee,
        nameEmployee: formatString(
          emp?.nameEmployee + " " + emp?.lastnameEmployee
        ),
      })),
    }))
  );

  return {
    isSuccess: true,
    status: response.status,
    message: response.message,
    data: servicesAdapt,
  };
};
