import { convertDateToStringYYYYMMDD } from "@Utilities/dates/convertDatesBetweenFormat";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the state of the slice
export interface ICalendarFilterState {
  clientAttended: any;
  showEventsOnTheDay: string;
  attendedByEmployee: string[];
  viewOnlyCustomer: string;
  appointmentStates: number[];
  showAllStates: boolean;
  hoveredReserveId: number | null;
}

// Define the initial state
const DEFAULT_STATE: ICalendarFilterState = {
  clientAttended: null,
  showEventsOnTheDay: convertDateToStringYYYYMMDD(),
  attendedByEmployee: ["Todos los empleados"],
  viewOnlyCustomer: "",
  appointmentStates: [],
  showAllStates: true,
  hoveredReserveId: null,
};

// Create the slice
const calendarFilterSlice = createSlice({
  name: "calendarFilter",
  initialState: DEFAULT_STATE,
  reducers: {
    // Goal: Change the client attended
    changeClientAttended: (state, action: PayloadAction<any>) => {
      state.clientAttended = action.payload;
    },

    // Goal: Change the show events on the day
    changeShowEventsOnTheDay: (state, action: PayloadAction<string>) => {
      state.showEventsOnTheDay = action.payload;
    },

    // Goal: Change the attended by employee
    changeAttendedByEmployee: (state, action: PayloadAction<string[]>) => {
      state.attendedByEmployee = action.payload;
    },

    changeTheClientSearchInput: (state, action: PayloadAction<string>) => {
      state.viewOnlyCustomer = action.payload;
    },

    changeAppointmentStateFilter: (state, action: PayloadAction<number[]>) => {
      state.appointmentStates = action.payload;
    },

    setShowAllStates: (state, action: PayloadAction<boolean>) => {
      state.showAllStates = action.payload;
    },

    setHoveredReserveId: (state, action: PayloadAction<number | null>) => {
      state.hoveredReserveId = action.payload;
    },
  },
});

export const {
  changeClientAttended,
  changeShowEventsOnTheDay,
  changeAttendedByEmployee,
  changeTheClientSearchInput,
  changeAppointmentStateFilter,
  setShowAllStates,
  setHoveredReserveId,
} = calendarFilterSlice.actions;

export default calendarFilterSlice.reducer as (
  state: ICalendarFilterState,
  action: PayloadAction<any>
) => ICalendarFilterState;
