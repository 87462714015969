export const formatString = (input: string): string => {
  // Check if the string is longer than 30 characters
  if (input === "") return "";
  if (input.length > 30) {
    // Cut the string to 27 characters and add three dots at the end
    input = input.substring(0, 27) + "...";
  }

  // Split the string into words
  const words = input.split(" ");

  // Format each word with the first letter in uppercase and the rest in lowercase
  const formattedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the formatted words into a single string and return it
  return formattedWords.join(" ");
};
