import { useState, useEffect, useRef } from "react";
import style from "../styles/StateFilterMenu.module.css";
//@ts-ignore
import { Icon } from "@viuti/recursos";
import { FilterIcon } from "@Models/icons";
import { useDispatch, useSelector } from "react-redux";
import { changeAttendedByEmployee } from "@ReduxService/slices/calendar/calendarFilter.slice";
import { RootState } from "@ReduxService/store";
import { capitalizeWords } from "@Utilities/strings/formatNames";
import { normalizeEmployeeName } from "@Utilities/strings/normalizeNames";

export const EmployeeFilterMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const employeesRedux = useSelector(
    (state: RootState) => state.appointments.employeesCached
  );

  const selectedEmployees = useSelector(
    (state: RootState) => state.calendarFilter.attendedByEmployee
  );

  const showAllEmployees =
    selectedEmployees.length === 1 &&
    selectedEmployees[0] === "Todos los empleados";

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleShowAllEmployees = () => {
    dispatch(changeAttendedByEmployee(["Todos los empleados"]));
  };

  const handleEmployeeChange = (employeeName: string) => {
    const normalizedName = normalizeEmployeeName(employeeName);

    if (selectedEmployees.includes(normalizedName)) {
      const newSelectedEmployees = selectedEmployees.filter(
        (name) => name !== normalizedName
      );

      dispatch(
        changeAttendedByEmployee(
          newSelectedEmployees.length === 0
            ? ["Todos los empleados"]
            : newSelectedEmployees
        )
      );
    } else {
      const newSelectedEmployees = showAllEmployees
        ? [normalizedName]
        : [...selectedEmployees, normalizedName];

      dispatch(changeAttendedByEmployee(newSelectedEmployees));
    }
  };

  return (
    <div className={style.menuContainer} ref={menuRef}>
      <button className={style.filterButton} onClick={() => setIsOpen(!isOpen)}>
        <span>Empleados</span>
        <Icon path={FilterIcon} size={16} color="#45348e" />
      </button>

      {isOpen && (
        <div className={style.menuDropdown}>
          <label className={`${style.menuItem} ${style.allStatesOption}`}>
            <input
              type="checkbox"
              checked={showAllEmployees}
              onChange={handleShowAllEmployees}
            />
            <span>Todos los empleados</span>
          </label>

          <div className={style.stateDivider} />

          {employeesRedux.map((employee, index) => {
            const employeeName = capitalizeWords(employee.value);
            const normalizedName = normalizeEmployeeName(employeeName);
            return (
              <label key={index} className={style.menuItem}>
                <input
                  type="checkbox"
                  checked={selectedEmployees.includes(normalizedName)}
                  onChange={() => handleEmployeeChange(employeeName)}
                />
                <span className={style.employeeName}>{employeeName}</span>
              </label>
            );
          })}
        </div>
      )}
    </div>
  );
};
