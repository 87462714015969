const formatDateToReadableString = (fechaStr: string): string => {
  // Split the date into parts
  const partesFecha = fechaStr.split("-");
  const year = partesFecha[0];
  const month = partesFecha[1];
  const day = partesFecha[2];

  // Create an array with the names of the months
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  // Get the name of the month from the array of months
  const monthname = months[parseInt(month) - 1];

  // Retornar la fecha formateada
  return `${monthname} ${day} de ${year}`;
};

export default formatDateToReadableString;
