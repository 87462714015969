import { fillNumberWithZeros } from "@Utilities/numbers/fillNumberWithZeros";

export function convert24FormatTo12FormatString(time24Format: string): string {
  if (time24Format.includes("AM") || time24Format.includes("PM"))
    return time24Format;
  const [hour, minutes] = time24Format.split(":");
  const parsedHour = parseInt(hour);
  const parsedMinutes = parseInt(minutes);

  if (parsedHour === 0) {
    return `00:${fillNumberWithZeros(parsedMinutes, 2)} AM`;
  }

  if (parsedHour === 12) {
    return `12:${fillNumberWithZeros(parsedMinutes, 2)} PM`;
  }

  if (parsedHour > 12) {
    return `${fillNumberWithZeros(parsedHour - 12, 2)}:${fillNumberWithZeros(
      parsedMinutes,
      2
    )} PM`;
  }

  return `${fillNumberWithZeros(parsedHour, 2)}:${fillNumberWithZeros(
    parsedMinutes,
    2
  )} AM`;
}

export function convert12FormatTo24FormatString(time12Format: string): string {
  if (!time12Format.includes("AM") && !time12Format.includes("PM"))
    return time12Format;
  const [hourMinute, period] = time12Format.split(" ");
  const [hour, minutes] = hourMinute.split(":");
  const parsedHour = parseInt(hour);
  const parsedMinutes = parseInt(minutes);

  if (period === "PM" && parsedHour !== 12) {
    return `${fillNumberWithZeros(parsedHour + 12, 2)}:${fillNumberWithZeros(
      parsedMinutes,
      2
    )}`;
  }

  if (period === "AM" && parsedHour === 12) {
    return `00:${fillNumberWithZeros(parsedMinutes, 2)}`;
  }

  return `${fillNumberWithZeros(parsedHour, 2)}:${fillNumberWithZeros(
    parsedMinutes,
    2
  )}`;
}
