// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XPBw5ZWWSFzMR_9_n_Eg {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  pointer-events: none;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Calendar/components/UnavailableHours/UnavailableHours.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,qCAAqC;EACrC,oBAAoB;EACpB,UAAU;AACZ","sourcesContent":[".unavailableBlock {\n  position: absolute;\n  width: 100%;\n  background-color: rgba(0, 0, 0, 0.03);\n  pointer-events: none;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unavailableBlock": `XPBw5ZWWSFzMR_9_n_Eg`
};
export default ___CSS_LOADER_EXPORT___;
