import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import {
  appointmentsSlice,
  IAppointmentsState,
  calendarFilterSlice,
  ICalendarFilterState,
  calendarHeaderSlice,
  ICalendarHeaderState,
  calendarViewSlice,
  ICalendarViewState,
  newAppointmentSlice,
  NewIAppointmentsState,
  servicesSlice,
} from "./slices/barrel";
import { IServiceState } from "@Models/types/slices";
import notificationSlice, {
  INotificationState,
} from "./slices/notification/notification.slice";
import editAppointmentSlice, {
  IEditAppointmentsState,
} from "./slices/modals/editAppointment.slice";
import appointmentDetailSlice, {
  IAppointmentDetailState,
} from "./slices/appointment/appointmentDetail.slice";
import appointmentStatesReducer, {
  IAppointmentStatesState,
} from "./slices/appointment/appointmentStates.slice";
import appointmentModalSlice, {
  IAppointmentModalState,
} from "./slices/modals/appointmentModal.slice";

// Define the type of the store
export type RootState = {
  calendarFilter: ICalendarFilterState;
  calendarView: ICalendarViewState;
  calendarHeaderSlice: ICalendarHeaderState;
  appointments: IAppointmentsState;
  services: IServiceState;
  notification: INotificationState;
  newAppointment: NewIAppointmentsState;
  editAppointment: IEditAppointmentsState;
  appointmentDetail: IAppointmentDetailState;
  appointmentStates: IAppointmentStatesState;
  appointmentModal: IAppointmentModalState;
};

// Create the store
export const store: EnhancedStore<RootState> = configureStore({
  reducer: {
    calendarFilter: calendarFilterSlice,
    calendarView: calendarViewSlice,
    calendarHeaderSlice: calendarHeaderSlice,
    appointments: appointmentsSlice,
    services: servicesSlice,
    newAppointment: newAppointmentSlice,
    notification: notificationSlice,
    appointmentDetail: appointmentDetailSlice,
    editAppointment: editAppointmentSlice,
    appointmentStates: appointmentStatesReducer,
    appointmentModal: appointmentModalSlice,
  },
});
