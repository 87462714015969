//@ts-ignore
import { SearchWithColoredBorder } from "@viuti/recursos";

export default function SearchWithColoredBorderBuilder({
  value,
  setValue,
  allData,
  label = null,
  placeholder = "Buscar",
  onClickResult = (item) => {},
  showResult,
  disabled = true,
}) {
  return (
    <SearchWithColoredBorder
      value={value}
      setValue={setValue}
      allData={allData}
      label={label}
      placeholder={placeholder}
      showResults={showResult}
      onClickResult={(item) => onClickResult(item)}
      clearInput={false}
      disabled={disabled}
    />
  );
}
