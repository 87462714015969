// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xtLEwRELchpoyWr5Gwdm {
  position: relative;
  min-width: 4rem;
  font-size: 0.6rem;
  height: calc(24 * 64px + 32px);
  border-right: 1px solid #e9e9e9;
}

.CQLBhEKG1eYGcBwNr1a6 {
  height: 32px;
  width: 100%;
}

.Ksa3tF4M5ad55bNU2Xzq,
.mB7ldYLD6TS5_cQ8y2S0 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  color: var(--CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE);
  padding: 0 0.5rem;
  height: 64px;
  width: 100%;
}

.mB7ldYLD6TS5_cQ8y2S0 {
  border-top: none;
}

.jI9ZB_XGH5LoazgedZ0I {
  font-size: 0.6rem;
  color: var(--CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE);
  margin-top: -5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Calendar/components/HourColumn/style/HourColumn.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,8BAA8B;EAC9B,+BAA+B;AACjC;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;;EAEE,aAAa;EACb,uBAAuB;EACvB,yBAAyB;;EAEzB,2DAA2D;EAC3D,iBAAiB;EACjB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,2DAA2D;EAC3D,gBAAgB;AAClB","sourcesContent":[".hourColumn {\n  position: relative;\n  min-width: 4rem;\n  font-size: 0.6rem;\n  height: calc(24 * 64px + 32px);\n  border-right: 1px solid #e9e9e9;\n}\n\n.hourColumn__prePadding {\n  height: 32px;\n  width: 100%;\n}\n\n.hourColumn__hour,\n.hourColumn__hourFirst {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-end;\n\n  color: var(--CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE);\n  padding: 0 0.5rem;\n  height: 64px;\n  width: 100%;\n}\n\n.hourColumn__hourFirst {\n  border-top: none;\n}\n\n.hourText {\n  font-size: 0.6rem;\n  color: var(--CARPENTER-VIUTI-CORE-COLOR-LIGHT-ON-BASEPLATE);\n  margin-top: -5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hourColumn": `xtLEwRELchpoyWr5Gwdm`,
	"hourColumn__prePadding": `CQLBhEKG1eYGcBwNr1a6`,
	"hourColumn__hour": `Ksa3tF4M5ad55bNU2Xzq`,
	"hourColumn__hourFirst": `mB7ldYLD6TS5_cQ8y2S0`,
	"hourText": `jI9ZB_XGH5LoazgedZ0I`
};
export default ___CSS_LOADER_EXPORT___;
