import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the state of the slice
export interface ICalendarHeaderState {
  headerDayRow: number[];
}

// Define the initial state
const DEFAULT_STATE: ICalendarHeaderState = {
  headerDayRow: [],
};

// Create the slice
const calendarHeaderSlice = createSlice({
  name: "calendarView",
  initialState: DEFAULT_STATE,
  reducers: {
    // Goal: Update the header day row
    updateHeaderDayRow: (state, action: PayloadAction<number[]>) => {
      state.headerDayRow = action.payload;
    },
  },
});

export const { updateHeaderDayRow } = calendarHeaderSlice.actions;

export default calendarHeaderSlice.reducer as (
  state: ICalendarHeaderState,
  action: PayloadAction<any>
) => ICalendarHeaderState;
