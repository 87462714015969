import { RootState } from "@ReduxService/store";
import { useSelector } from "react-redux";

export const useGetDaysAndHoursToRender = () => {
  // Get the day selected on the filter
  const daySelected = useSelector(
    (state: RootState) => state.calendarFilter.showEventsOnTheDay
  );

  // Get the total of days to show on the calendar
  const totalOfDaysToShow = useSelector(
    (state: RootState) => state.calendarView.daysToShow
  );

  // Get the total of hours to show on the calendar
  const totalHoursToShow = useSelector(
    (state: RootState) => state.calendarView.hoursToShow
  );

  return {
    daySelected,
    totalOfDaysToShow,
    totalHoursToShow,
  };
};
