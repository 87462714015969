import { sumMinutesToTime } from "@Utilities/hours/opertaionsHours";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

// Define the state of the slice
export interface IEditAppointmentsState {
  reserveId: number;
  deleteReserveDetailsIds: number[];
  newReserveDetails: {
    priceServiceId: number;
    employeeId: number;
    reserveHour: string;
    promoId: number;
  }[];
  editReserveDetails: {
    reserveDetailId: number;
    priceServiceId: number;
    employeeId: number;
    reserveHour: string;
  }[];
  hasEdition: boolean;
}

// Define the initial state
const DEFAULT_STATE: IEditAppointmentsState = {
  reserveId: 0,
  deleteReserveDetailsIds: [],
  newReserveDetails: [],
  editReserveDetails: [],
  hasEdition: false,
};

// Create the slice
const editAppointmentSlice = createSlice({
  name: "appointments",
  initialState: DEFAULT_STATE,
  reducers: {
    resetAppointmentEdit: (state) => {
      // state.reserveId = 0;
      state.deleteReserveDetailsIds = [];
      state.newReserveDetails = [];
      state.editReserveDetails = [];
      state.hasEdition = false;
    },
    addAppointmentId: (state, action: PayloadAction<number>) => {
      state.reserveId = action.payload;
    },
    deleteAppointmentId: (state, action: PayloadAction<number>) => {
      state.deleteReserveDetailsIds.push(action.payload);
      state.hasEdition = true;
    },
    addNewAppointment: (
      state,
      action: PayloadAction<{
        priceServiceId: number;
        employeeId: number;
        reserveHour: string;
        promoId: number;
      }>
    ) => {
      state.newReserveDetails.push(action.payload);
      state.hasEdition = true;
    },
    addEditAppointment: (
      state,
      action: PayloadAction<{
        reserveDetailId: number;
        priceServiceId: number;
        employeeId: number;
        reserveHour: string;
      }>
    ) => {
      const copyEdit = state.editReserveDetails;
      const index = copyEdit.findIndex(
        (item) => item.reserveDetailId === action.payload.reserveDetailId
      );
      if (index !== -1) {
        copyEdit[index] = action.payload;
        state.editReserveDetails = copyEdit;
        return;
      }
      state.editReserveDetails.push(action.payload);
      state.hasEdition = true;
    },
  },
});

export const {
  addAppointmentId,
  addNewAppointment,
  deleteAppointmentId,
  addEditAppointment,
  resetAppointmentEdit,
} = editAppointmentSlice.actions;

export default editAppointmentSlice.reducer as (
  state: IEditAppointmentsState,
  action: PayloadAction<any>
) => IEditAppointmentsState;
