// GOAL: Detect collision between events
export function calculateTotalCollisions(reservations): void {
  // For each event, calculate the total collisions
  for (const event of reservations) {
    // Initialize the total collisions and the collision id
    event.totalCollisions = 0;
    event.collisionId = [];

    // For each other event, calculate the collision
    for (const otherEvent of reservations) {
      // If the events are different, calculate the collision
      // else, do nothing and continue with the next event
      if (event.reserveId !== otherEvent.reserveId) {
        // Calculate the start and end of the event
        const [startEventDay, startEventMonth, startEventYear] =
          event.services[0].hourReserve.split(":");
        const startEvent = new Date(
          `${startEventYear}-${startEventMonth}-${startEventDay}T${event.services[0].hourReserve}`
        );
        const endEvent = new Date(
          startEvent.getTime() + event.services[0].serviceDuration * 60000
        );

        // Calculate the start and end of the other event
        const [startOtherEventDay, startOtherEventMonth, startOtherEventYear] =
          otherEvent.services[0].hourReserve.split(":");
        const startOtherEvent = new Date(
          `${startOtherEventYear}-${startOtherEventMonth}-${startOtherEventDay}T${otherEvent.services[0].hourReserve}`
        );
        const endOtherEvent = new Date(
          startOtherEvent.getTime() +
            otherEvent.services[0].serviceDuration * 60000
        );

        // If the events collide, add the collision id
        if (
          (startEvent < endOtherEvent && endEvent > startOtherEvent) ||
          (startOtherEvent < endEvent && endOtherEvent > startEvent)
        ) {
          // Add the collision id
          event.collisionId.push(otherEvent.reserveId);
          event.totalCollisions++;
        }
      }
    }
  }
}

export function detectCollision(reservations) {
  const result = [];

  for (let i = 0; i < reservations.length; i++) {
    const eventA = reservations[i];
    eventA.collisionCount = 0;
    eventA.collisionSameDay = 0;
    eventA.collisionSameDayIndex = 0;

    const eventsOnSameDay = reservations.filter(
      (event) => event.dateReserve === eventA.dateReserve
    );
    eventA.collisionSameDay = eventsOnSameDay.length;

    const collisionSameDayIndices: { [key: string]: number } = {};
    eventsOnSameDay.forEach((event, index) => {
      event.collisionSameDayIndex =
        collisionSameDayIndices[event.services[0].hourReserve] || 0;
      collisionSameDayIndices[event.services[0].hourReserve] =
        event.collisionSameDayIndex + 1;
    });

    for (let j = 0; j < reservations.length; j++) {
      if (i !== j) {
        const eventB = reservations[j];

        const startA = new Date(`2024-02-27T${eventA.services[0].hourReserve}`);
        const endA = new Date(
          startA.getTime() + eventA.services[0].serviceDuration * 60000
        );

        const startB = new Date(`2024-02-27T${eventB.services[0].hourReserve}`);
        const endB = new Date(
          startB.getTime() + eventB.services[0].serviceDuration * 60000
        );

        if (
          (startA < endB && endA > startB) ||
          (startB < endA && endB > startA)
        ) {
          result.push([eventA, eventB]);
          eventA.collisionCount++;
        }
      }
    }

    eventA.collisionId = `collision-${eventA.dateReserve}-${eventA.reserveId}-${eventA.collisionSameDay}-${eventA.collisionCount}-${eventA.collisionSameDayIndex}`;
  }

  return result;
}

export function sortReservationsByTime(reservations) {
  return reservations.sort((a, b) => {
    const hourA = a.services[0].hourReserve;
    const hourB = b.services[0].hourReserve;
    return hourA.localeCompare(hourB);
  });
}

export default sortReservationsByTime;
