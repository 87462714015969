import { IServicePayload, IServiceState } from "@Models/types/slices";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the initial state
const DEFAULT_STATE: IServiceState = {
  services: [],
};

// Create the slice
const calendarViewSlice = createSlice({
  name: "services",
  initialState: DEFAULT_STATE,
  reducers: {
    // Goal: Load the appointments for the week, to be displayed in the calendar
    loadAllServicesOfTheWeek: (
      state,
      action: PayloadAction<IServicePayload[]>
    ) => {
      state.services = action.payload;
    },
  },
});

export const { loadAllServicesOfTheWeek } = calendarViewSlice.actions;

export default calendarViewSlice.reducer as (
  state: IServiceState,
  action: PayloadAction<any>
) => IServiceState;
