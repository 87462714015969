import { useSelector } from "react-redux";
import { RootState } from "@ReduxService/store";
import style from "./CalendarColumn.module.css";
import { Appointments } from "../Appointments/Appointments";
import { HourGuides } from "../TimeMarkers/HourGuides";
import { UnavailableHours } from "../UnavailableHours/UnavailableHours";

interface CalendarColumnProps {
  day: Date;
  employee: string;
}

export const CalendarColumn = ({ day, employee }: CalendarColumnProps) => {
  const viewMode = useSelector(
    (state: RootState) => state.calendarView.viewMode
  );
  const hours = useSelector((state: RootState) => state.calendarView.hours);

  const adjustedDay = new Date(day);
  const currentAvailableHours = hours.filter(
    (h) => h.desabled === 0 && h.idCodeDay === day.getDay() + 1
  );

  return (
    <div
      className={`${style.column} ${viewMode === "day" ? style.columnDay : ""}`}
    >
      <div className={style.columnContent}>
        <HourGuides />
        <UnavailableHours availableHours={currentAvailableHours} />
        <Appointments day={adjustedDay} employee={employee} />
      </div>
    </div>
  );
};
