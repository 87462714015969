import { useTimeCalculations } from "../../hooks/useTimeCalculations";
import style from "../../styles/Calendar.module.css";

export const HourGuides = () => {
  const { HOUR_HEIGHT, PRE_PADDING } = useTimeCalculations();

  return (
    <>
      {Array.from({ length: 24 }, (_, i) => (
        <div
          key={i}
          className={style.hourGuide}
          style={{
            top: `${i * HOUR_HEIGHT + PRE_PADDING}px`,
          }}
        />
      ))}
    </>
  );
};
