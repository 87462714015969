import { GET_LOUNGE_HOUR_API } from "@Models/services/lounge.services";
import axios from "axios";
import { authorizeTheCallToApi } from "../API/authorization";
import { getLoungeIdFromLocalStorage } from "@Services/API/localstorage";

export const ApiGetLounge = async () => {
  try {
    // 1. Get the loungeId from the local storage
    const loungeId = getLoungeIdFromLocalStorage();

    // 2. Generate the params
    const PARAMS = {
      idLounge: loungeId,
    };

    //3. Generate the authorization
    const AUTHORIZATION = { ...authorizeTheCallToApi() };

    // 4. Generate the config
    const CONFIG = {
      params: PARAMS,
      ...AUTHORIZATION,
    };

    // 5. Get the hours of the selected lounge
    const response = await axios.get(GET_LOUNGE_HOUR_API, CONFIG);

    // 6. Return the data of the response
    return response.data;
  } catch (error) {
    return {
      status: 500,
      data: [],
      message:
        error?.response?.data?.message ||
        " Ocurrió un error al obtener los horarios",
    };
  }
};
