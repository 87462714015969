import { changeDaysToShow } from "@ReduxService/slices/calendar/calendarView.slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@ReduxService/store";

export default function useWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const viewMode = useSelector(
    (state: RootState) => state.calendarView.viewMode
  );
  const employees = useSelector(
    (state: RootState) => state.appointments.employeesCached
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (viewMode === "week") {
      dispatch(changeDaysToShow(7));
    } else {
      dispatch(changeDaysToShow(employees.length || 1));
    }
  }, [width, viewMode, employees.length, dispatch]);

  return width;
}
