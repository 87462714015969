import { apiGetAppointments } from "@Services/GET/getAppointments.service";

export const getAppointmentsOnTheWeekAdapter = async ({
  startDate = new Date(),
  endDate = new Date(),
}) => {
  const appointments = await apiGetAppointments({
    fromDate: startDate,
    toDate: endDate,
  });
  return {
    isSuccess: appointments.status === 200,
    appointments: appointments.data.data,
    message: appointments.message,
  };
};
