import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAppointmentDetailState {
  appointmentDetail: any;
}

const initialState: IAppointmentDetailState = {
  appointmentDetail: null,
};

const appointmentDetailSlice = createSlice({
  name: "appointmentDetail",
  initialState,
  reducers: {
    updateAppointmentDetail: (
      state,
      action: PayloadAction<IAppointmentDetailState>
    ) => {
      state.appointmentDetail = action.payload;
    },
    resetAppointmentDetail: (state) => {
      state.appointmentDetail = null;
    },
  },
});

export const { updateAppointmentDetail, resetAppointmentDetail } =
  appointmentDetailSlice.actions;
export default appointmentDetailSlice.reducer;
