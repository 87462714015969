import { RootState } from "@ReduxService/store";
import { useSelector } from "react-redux";
import Appointment from "../Appointment/Appointment";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { useMemo } from "react";

interface Service {
  hourReserve: string;
  serviceDuration: number;
  employeeName: string;
}

interface AppointmentService {
  service: {
    hourReserve: string;
    serviceDuration: number;
    employeeName: string;
    serviceName?: string;
  };
  appointment: {
    reserveId: number;
    clientName: string;
    status: number;
    services: Array<{
      hourReserve: string;
      serviceDuration: number;
      employeeName: string;
      serviceName?: string;
    }>;
  };
}

export function Appointments({ day, employee }) {
  const getAppointments = useSelector(
    (state: RootState) => state.appointments.appointmentsWeek
  );
  const screenWidth = useScreenWidth();

  const getServicesOfTheDay = useMemo(() => {
    const appointmentsOfDay = getAppointments.filter((app) => {
      const date = app.dateReserve.split("/");
      const [dayDate, monthDate, yearDate] = date;
      return (
        new Date(
          Number(yearDate),
          Number(monthDate) - 1,
          Number(dayDate)
        ).toDateString() === day.toDateString()
      );
    });

    return appointmentsOfDay.flatMap((appointment) =>
      appointment.services
        .filter(
          (service) =>
            employee === "Todos los empleados" ||
            service.employeeName.toLowerCase() === employee.toLowerCase()
        )
        .map((service) => ({
          service,
          appointment: {
            reserveId: appointment.reserveId,
            clientName: appointment.clientName,
            status: appointment.status,
            services: appointment.services,
          },
        }))
    );
  }, [getAppointments, day, employee]);

  // Función auxiliar para verificar si dos servicios se solapan
  const doServicesOverlap = (
    service1: AppointmentService,
    service2: AppointmentService
  ) => {
    const [hour1, min1] = service1.service.hourReserve.split(":").map(Number);
    const [hour2, min2] = service2.service.hourReserve.split(":").map(Number);

    const start1 = hour1 * 60 + min1;
    const end1 = start1 + service1.service.serviceDuration;
    const start2 = hour2 * 60 + min2;
    const end2 = start2 + service2.service.serviceDuration;

    return start1 < end2 && start2 < end1;
  };

  // Agrupar servicios que se solapan
  const getOverlappingGroups = () => {
    const groups = [];
    const processed = new Set();

    getServicesOfTheDay.forEach((service, index) => {
      if (processed.has(index)) return;

      const group = [index];
      processed.add(index);

      getServicesOfTheDay.forEach((otherService, otherIndex) => {
        if (
          index !== otherIndex &&
          !processed.has(otherIndex) &&
          doServicesOverlap(service, otherService)
        ) {
          group.push(otherIndex);
          processed.add(otherIndex);
        }
      });

      groups.push(group);
    });

    return groups;
  };

  const overlappingGroups = getOverlappingGroups();

  return (
    <div>
      {getServicesOfTheDay.map((serviceApp, index) => {
        const widthMobile = screenWidth <= 767 ? 85 : 95;

        const group = overlappingGroups.find((g) => g.includes(index));
        const width = widthMobile / group.length;
        const position = group.indexOf(index);
        const left = position * width;

        const uniqueKey = `${serviceApp.appointment.reserveId}-${index}-${serviceApp.service.employeeName}`;

        return (
          <Appointment
            key={uniqueKey}
            detail={serviceApp}
            width={width}
            left={left}
            day={day}
          />
        );
      })}
    </div>
  );
}
