import { convert24FormatTo12FormatString } from "@Utilities/adapterTypes/format24HoursTo12HoursString";

// Goal: Sum minutes to a time string
// Example: sumMinutesToTime("01:00 PM", 60) => "02:00 PM"
//! Important the result is in 24-hour format
export function sumMinutesToTime(timeStr: string, minutes: number): string {
  if (minutes === 0) return timeStr;
  // Separating hours and minutes
  const [hoursStr, minutesStr, _] = timeStr.split(/[: ]/);

  // Parse hours and minutes
  let hours = parseInt(hoursStr);
  let newMinutes = parseInt(minutesStr);

  // Calculate total minutes
  let totalMinutes = hours * 60 + newMinutes + minutes;

  // Convert to 24-hour format
  hours = Math.floor(totalMinutes / 60) % 24;
  newMinutes = totalMinutes % 60;

  // Adjust for midnight
  if (hours === 0) {
    hours = 12;
  }

  // Format new time
  let newTimeStr =
    (hours < 10 ? "0" : "") +
    hours +
    ":" +
    (newMinutes < 10 ? "0" : "") +
    newMinutes;

  return convert24FormatTo12FormatString(newTimeStr);
}
