import React, { useState } from "react";
import { HiArrowPath } from "react-icons/hi2";
import style from "./Reload.module.css";
import { useDispatch } from "react-redux";
import { clearCache } from "@ReduxService/slices/appointment/appointments.slice";
import { useGetDaysAndHoursToRender } from "@Components/ui/Calendar/hooks/getDaysAndHoursToShow";
import { changeShowEventsOnTheDay } from "@ReduxService/slices/calendar/calendarFilter.slice";
import { updateHeaderDayRow } from "@ReduxService/slices/calendar/calendarHeader.slice";
import { getAppointmentsAndUpdateCache } from "@Hooks/useGetAppointments";

export const Reload = () => {
  const dispatch = useDispatch();
  const { daySelected } = useGetDaysAndHoursToRender();
  const [isLoading, setIsLoading] = useState(false);

  const handleReload = () => {
    if (isLoading) return;
    setIsLoading(true);
    dispatch(clearCache());
    const dateObject = new Date(daySelected);
    dateObject.setDate(dateObject.getDate() + 1);
    getAppointmentsAndUpdateCache({
      dispatch,
      appointmentsCached: [],
      day: dateObject,
    });
    dispatch(changeShowEventsOnTheDay(daySelected));
    dispatch(updateHeaderDayRow([dateObject.getTime()]));

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <div className={style.reloadContainer}>
      <button
        className={`${style.reload} ${isLoading ? style.rotating : ""}`}
        onClick={handleReload}
        disabled={isLoading}
      >
        <HiArrowPath />
      </button>
    </div>
  );
};
