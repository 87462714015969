import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@ReduxService/store";
import { normalizeEmployeeName } from "@Utilities/strings/normalizeNames";
import { useGetDaysAndHoursToRender } from "./getDaysAndHoursToShow";

type ViewMode = "day" | "week" | "month";

interface Column {
  key: string;
  day: Date;
  employee: string;
  type: "employee" | "weekDay" | "monthDay";
}

const generateWeekDays = (baseDate: Date): Column[] => {
  const weekDays: Column[] = [];

  // Creamos una nueva fecha y ajustamos para manejar la zona horaria
  const startDate = baseDate;

  // Obtenemos el día actual de la semana (0 = domingo, 1 = lunes, ..., 6 = sábado)
  let currentDay = startDate.getDay();

  // Convertimos domingo (0) a 7 para facilitar el cálculo
  currentDay = currentDay === 0 ? 7 : currentDay;

  // Calculamos la diferencia hasta el lunes de la semana que contiene la fecha seleccionada
  const diff = -(currentDay - 1);

  // Ajustamos la fecha al lunes de la semana
  startDate.setDate(startDate.getDate() + diff);

  // Generamos los días de la semana comenzando desde el lunes
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);
    weekDays.push({
      key: `day-${currentDate.toISOString()}`,
      day: currentDate,
      employee: "Todos los empleados",
      type: "weekDay",
    });
  }

  return weekDays;
};

const generateEmployeeColumns = (
  employees: Array<{ value: string }>,
  selectedDay: Date
): Column[] => {
  const adjustedDate = new Date(selectedDay);
  adjustedDate.setDate(adjustedDate.getDate() + 1);

  return employees.map((emp) => ({
    key: `employee-${emp.value}`,
    day: adjustedDate,
    employee: normalizeEmployeeName(emp.value),
    type: "employee",
  }));
};

export const useCalendarColumns = () => {
  const { daySelected } = useGetDaysAndHoursToRender();
  const viewMode = useSelector(
    (state: RootState) => state.calendarView.viewMode
  );
  const employeesRedux = useSelector(
    (state: RootState) => state.appointments.employeesCached
  );

  const columns = useMemo(() => {
    // Parseamos la fecha considerando la zona horaria local
    const [year, month, day] = daySelected.split("-");
    const selectedDate = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      12,
      0,
      0
    );

    switch (viewMode as ViewMode) {
      case "day":
        return generateEmployeeColumns(employeesRedux, selectedDate);
      case "week":
        return generateWeekDays(selectedDate);
      case "month":
        return generateWeekDays(selectedDate);
      default:
        return generateWeekDays(selectedDate);
    }
  }, [daySelected, viewMode, employeesRedux]);

  return {
    columns,
    viewMode,
    totalColumns: columns.length,
  };
};
