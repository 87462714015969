import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the state of the slice
export interface INotificationState {
  state: {
    message: string;
    status: 200 | 400;
  };
}

// Define the initial state
const DEFAULT_STATE: INotificationState = {
  state: {
    message: "",
    status: null,
  },
};

// Create the slice
const sliceNotificationSlice = createSlice({
  name: "appointments",
  initialState: DEFAULT_STATE,
  reducers: {
    showSuccessNotification: (state, action: PayloadAction<string>) => {
      state.state.message = action.payload;
      state.state.status = 200;
    },
    showErrorNotification: (state, action: PayloadAction<string>) => {
      state.state.message = action.payload;
      state.state.status = 400;
    },
    resetNotification: (state) => {
      state.state.message = "";
      state.state.status = null;
    },
  },
});

export const {
  showSuccessNotification,
  showErrorNotification,
  resetNotification,
} = sliceNotificationSlice.actions;

export default sliceNotificationSlice.reducer as (
  state: INotificationState,
  action: PayloadAction<any>
) => INotificationState;
