import { getAccessTokenFromLocalStorage } from "./localstorage";

export const authorizeTheCallToApi = () => {
  // 1. Get the access token from the local storage
  const ACCESS_TOKEN = getAccessTokenFromLocalStorage();

  // 2. Generate the authorization
  const AUTHORIZATION = `Bearer ${ACCESS_TOKEN}`;

  // 3. Generate the headers
  const HEADERS = {
    headers: {
      Authorization: AUTHORIZATION,
    },
  };

  // 4. Return the headers
  return HEADERS;
};
