import { IReservationWeekRenderSlice } from "@Models/types/slices";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { capitalizeWords } from "@Utilities/strings/formatNames";

interface IAppointmentsFetchStatus {
  status: "loading" | "succeeded" | "failed";
  error: string | null;
}

// Define the state of the slice
export interface IAppointmentsState {
  appointmentsWeek: IReservationWeekRenderSlice[];
  appointmentWeekCached: any[];
  employeesCached: any[];
  customerCached: any[];
  appointmentsFetchStatus: IAppointmentsFetchStatus;
}

// Define the initial state
const DEFAULT_STATE: IAppointmentsState = {
  appointmentsWeek: [],
  appointmentWeekCached: [],
  employeesCached: [],
  customerCached: [],
  appointmentsFetchStatus: {
    status: "loading",
    error: null,
  },
};

// Create the slice
const calendarViewSlice = createSlice({
  name: "appointments",
  initialState: DEFAULT_STATE,
  reducers: {
    // Goal: Load the appointments for the week
    loadAppointmentsWeek: (
      state,
      action: PayloadAction<IReservationWeekRenderSlice[]>
    ) => {
      // Add the employees to the cache
      const employees = [];
      action.payload.map((appointment) => {
        appointment.services.map((service) => {
          employees.push({
            id: service.employeeId,
            value: capitalizeWords(service.employeeName),
          });
        });
      });
      //remove duplicates from the employees array and the employeesCached array
      state.employeesCached = state.employeesCached.concat(employees);
      state.employeesCached = state.employeesCached.filter(
        (employee, index, self) =>
          index === self.findIndex((t) => t.id === employee.id)
      );
      // Add the appointments to the state
      state.appointmentsWeek.push(...action.payload);
    },

    // Goal: Load the appointments for the week from the cache
    loadAppointmentsWeekCached: (state, action: PayloadAction<any[]>) => {
      state.appointmentWeekCached.push(...action.payload);
    },

    // Goal: add a new appointment to the state
    addNewAppointmentOnTheGlobalState: (
      state,
      action: PayloadAction<IReservationWeekRenderSlice>
    ) => {
      // add the employees to the cache
      const employees = [];
      action.payload.services.map((service) => {
        employees.push({
          id: service.employeeId,
          value: service.employeeName,
        });
      });
      //remove duplicates from the employees array and the employeesCached array
      state.employeesCached = state.employeesCached.concat(employees);
      state.employeesCached = state.employeesCached.filter(
        (employee, index, self) =>
          index === self.findIndex((t) => t.employeeId === employee.employeeId)
      );

      // Add the appointment to the state
      state.appointmentsWeek.push(action.payload);
    },

    // Goal: Add the customer to the cache
    addCustomerToTheCache: (state, action: PayloadAction<any>) => {
      state.customerCached.push(action.payload);
    },

    updateCachedAppointment: (state, action: PayloadAction<any>) => {
      state.appointmentWeekCached = action.payload;
    },

    changeAppointmentStatus: (
      state,
      action: PayloadAction<{ reserveId: number; newStatus: number }>
    ) => {
      state.appointmentsWeek = state.appointmentsWeek.map((appointment) => {
        if (appointment.reserveId === action.payload.reserveId) {
          return {
            ...appointment,
            status: action.payload.newStatus,
          };
        }
        return appointment;
      });
    },

    setAppointmentsFetchStatus: (
      state,
      action: PayloadAction<IAppointmentsFetchStatus>
    ) => {
      state.appointmentsFetchStatus = action.payload;
    },

    // Goal: Limpiar todo el caché
    clearCache: (state) => {
      state.appointmentWeekCached = []; // Restablecer el caché de citas
      state.employeesCached = []; // Restablecer el caché de empleados
      state.customerCached = []; // Restablecer el caché de clientes
      state.appointmentsWeek = [];
    },
  },
});

export const {
  loadAppointmentsWeek,
  loadAppointmentsWeekCached,
  addNewAppointmentOnTheGlobalState,
  addCustomerToTheCache,
  updateCachedAppointment,
  changeAppointmentStatus,
  setAppointmentsFetchStatus,
  clearCache,
} = calendarViewSlice.actions;

export default calendarViewSlice.reducer as (
  state: IAppointmentsState,
  action: PayloadAction<any>
) => IAppointmentsState;
